import { createReducer, on } from '@ngrx/store';
import { TicketCount } from 'projects/oct-report-lib/src/public-api';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import { loadPSATenantTicketCount, loadPSATenantTicketCountFailure, loadPSATenantTicketCountSuccess } from './actions';

export const featureKey = 'reportPSATenantTicketCount';

export interface State extends Status {
    data: TicketCount[];
}

export const initialState: State = {
    data: null,
    ...initialStatus,
};

export const reducer = createReducer(
    initialState,

    // LOAD
    on(loadPSATenantTicketCount, (state) => ({ ...state, loading: true })),
    on(loadPSATenantTicketCountSuccess, (state, { _msp, _tenant, data }) => ({
        ...state,
        _msp,
        _tenant,
        data,
        loading: false,
        loaded: true,
    })),
    on(loadPSATenantTicketCountFailure, (state, { _msp, _tenant, error }) => ({
        ...state,
        _msp,
        _tenant,
        error,
        loading: false,
        loaded: false,
    })),
);

export const selectData = (state: State) => state.data;
