import { AuditLogRecordType } from '../../interfaces/management/schema.interface';
import { Meta } from '../../interfaces/meta.interface';

import ExchangeAdmin from './ExchangeAdmin';
import ExchangeItem from './ExchangeItem';
import ExchangeItemGroup from './ExchangeItemGroup';

const records: Partial<{ [key in AuditLogRecordType]: { [key: string]: Meta<any> } }> = {
    [AuditLogRecordType.ExchangeAdmin]: ExchangeAdmin,
    [AuditLogRecordType.ExchangeItem]: ExchangeItem,
    [AuditLogRecordType.ExchangeItemGroup]: ExchangeItemGroup,
}

export default records;