import { Meta } from '../../interfaces/meta.interface';
import { FileAccessedOrDownloadedClx } from '../SharePoint/SharePointFileOperation';

type FileAccessedFields = {
    ObjectId: string
}

type FileDownloadedFields = {
    ObjectId: string
}

const FileAccessed: Meta<FileAccessedFields> = {
    info: {
        title: 'OneDrive File Accessed',
        description: 'A user or system account viewed a file.',
    },
    extractor: (log: any) => {
        return {
            ObjectId: log.ObjectId
        }
    },
    classifications: FileAccessedOrDownloadedClx('OneDrive File Accessed','A file was accessed on OneDrive.')
}

const FileDownloaded: Meta<FileDownloadedFields> = {
    info: {
        title: 'OneDrive File Downloaded',
        description: 'A user downloaded a file.',
    },
    extractor: (log: any) => {
        return {
            ObjectId: log.ObjectId
        }
    },
    classifications: FileAccessedOrDownloadedClx('OneDrive File Downloaded','A file was downloaded from OneDrive.')
}

const operations = {
    'FileAccessed': FileAccessed,
    'FileDownloaded': FileDownloaded
}

export default operations;