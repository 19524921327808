import { Classification, Meta } from "../../interfaces/meta.interface";

type Fields = any;

function TIUrlClickData_description(fields: TIUrlClickDataDescFields) {
    return `A Malicious Url "${fields.Url}" was clicked in a mail.`
}

type TIUrlClickDataDescFields = {
    Url: string
}

const TIUrlClickDataClx: Classification<any, TIUrlClickDataDescFields>[] = [
    {
        severity: 'critical',
        title: 'Malicious URL Clicked',
        description: (fields: any) => TIUrlClickData_description(fields)
    }
]

const TIUrlClickData: Meta<any, any> = {
    info: {
        title: 'Malicious URL Clicked',
        description: 'A Malicious URL was clicked.'
    },
    description_extractor: (log: any) => {
        return {
            Url: log.Url
        }
    },
    classifications: TIUrlClickDataClx
}

const operations: { [key: string]: Meta<Fields> } = {
    'TIUrlClickData': TIUrlClickData
}

export default operations;