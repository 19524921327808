import { User } from '@microsoft/microsoft-graph-types-beta';
import { map, Observable, takeWhile } from 'rxjs';

// Summary
export function CustomActionDeduplicateOperator<T>(action: T, fetched_num_days: Observable<number>): Observable<T> {
    return fetched_num_days.pipe(
        takeWhile((fetched_num_days) => action['num_days'] > fetched_num_days),
        map((_) => action),
    );
}

export function UserHasMailbox(user: User): boolean {
    const exchangePlans = user.assignedPlans.filter(
        (plan) => plan.service.toLowerCase() === 'exchange' && plan.capabilityStatus !== 'Deleted',
    );

    return user.mail !== null && user.userType !== 'Guest' && exchangePlans.length > 0;
}
