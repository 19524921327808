import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ChartOptions, ChartType } from 'chart.js';
import { LabelOptions } from 'chartjs-plugin-annotation';
import { Severities, SeverityMap } from '@octiga/microsoft-events/src/interfaces/summary.interface';
import { BaseChartDirective } from 'ng2-charts';
import { Subscription } from 'rxjs';
import { client } from 'src/app/stores/client';

const EMPTY = [0, 0, 0, 0, 0, 0, 0];
const NULLED = [null, null, null, null, null, null, null];

function dateRange(start: string, end: string): string[] {
    const dates = [];
    const currentDate = new Date(start);

    while (currentDate <= new Date(end)) {
        dates.push(new Date(currentDate).toISOString().split('T')[0]);
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
}

function groupSummaryData(data: SeverityMap, start_date: string, end_date: string): any {
    let entries = Object.entries(data);
    const weekDates = dateRange(start_date, end_date);

    const current = {
        start_date: null,
        end_date: null,
        dates: Array.from(NULLED),
        info: Array.from(EMPTY),
        warning: Array.from(EMPTY),
        danger: Array.from(EMPTY),
        critical: Array.from(EMPTY),
        total: Array.from(EMPTY),
    };

    for (let j = 0; j < 7; j++) {
        let date: string = weekDates[j];
        let counts: Severities = {
            total: 0,
            critical: 0,
            danger: 0,
            warning: 0,
            info: 0,
        };
        if (entries.length > 0) {
            if (!!Object.keys(data).find((d) => d === date)) {
                date = entries[0][0];
                counts = entries[0][1];
                entries = entries.slice(1); // left pop
            }
        }
        if (current.start_date === null) {
            current.start_date = weekDates[0];
            current.end_date = weekDates[weekDates.length - 1];
        }

        current.dates[j] = date;
        current.info[j] = counts.info;
        current.warning[j] = counts.warning;
        current.danger[j] = counts.danger;
        current.critical[j] = counts.critical;
        current.total[j] = counts.total;
    }
    return current;
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function formatDate(date: Date): string {
    const month = months[date.getUTCMonth()];
    return `${date.getUTCDate()} ${month}`;
}

function setDateLabelsInTimeFrame(dataGroups: any, index: number) {
    let prev_date;

    return dataGroups.dates.map((date, i, array) => {
        if (!!date) {
            const new_date = new Date(date.split('T')[0]);
            return formatDate(new_date);
        } else {
            if (!prev_date) {
                prev_date = array[i - 1];
            }
            if (!prev_date) {
                let j = i + 1;
                while (array[j] === null) {
                    j += 1;
                }
                const new_date = new Date(array[j].split('T')[0]);
                new_date.setDate(new_date.getDate() - (j - i));
                return formatDate(new_date);
            } else {
                const new_date = new Date(prev_date.split('T')[0]);
                new_date.setDate(new_date.getDate() + 1);
                prev_date = new_date.toISOString();
                return formatDate(new_date);
            }
        }
    });
}

@Component({
    selector: 'app-monitoring-week-summary-chart',
    templateUrl: './monitoring-week-summary-chart.component.html',
    styleUrls: ['./monitoring-week-summary-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonitoringSummaryChartComponent implements OnInit, OnDestroy {
    @Output() loaded: EventEmitter<boolean> = new EventEmitter(false);
    @Input() tenant_id: string;
    @Input() weekData: SeverityMap;

    @ViewChild(BaseChartDirective) chart: BaseChartDirective;

    subscriptions: Subscription[] = [];
    public barChartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                type: 'logarithmic',
                stacked: true,
                display: false,
            },
        },
        plugins: {
            legend: {
                labels: {
                    boxHeight: 12,
                    boxWidth: 12,
                    font: {
                        size: 14,
                        lineHeight: 14,
                    },
                },
            },
        },
    };
    public barChartLabels: LabelOptions[] = [];
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartPlugins = [];

    public start_date = '';
    public end_date = '';

    public mixedChartData: any[];

    private dataGroups: {
        critical: Array<number>;
        danger: Array<number>;
        dates: Array<string>;
        start_date: string;
        end_date: string;
        info: Array<number>;
        total: Array<number>;
        warning: Array<number>;
    } = {
            critical: [0, 0, 0, 0, 0, 0, 0],
            danger: [0, 0, 0, 0, 0, 0, 0],
            dates: [],
            end_date: new Date().toISOString().split('T')[0],
            info: [0, 0, 0, 0, 0, 0, 0],
            start_date: new Date(new Date().setDate(new Date().getDate() - 6)).toISOString().split('T')[0],
            total: [0, 0, 0, 0, 0, 0, 0],
            warning: [0, 0, 0, 0, 0, 0, 0],
        };
    private dataIndex = 0;

    public isLoaded = false;

    constructor(private store: Store<any>) {}

    ngOnInit() {
        this.mixedChartData = this.getMixedChartData();

        this.loaded.emit(false);

        const today_iso = new Date().toISOString().split('T')[0];
        const week_ago_date = new Date();
        week_ago_date.setDate(week_ago_date.getDate() - 6);
        const week_ago_iso = week_ago_date.toISOString().split('T')[0];

        this.start_date = week_ago_iso;
        this.end_date = today_iso + 'T23:59:59.999Z';

        if (!!this.weekData) {
            this.initChartData(this.weekData);
        } else {
            const sub = this.store
                .pipe(select(client(this.tenant_id).severity.date.byDateRange(this.start_date, this.end_date)))
                .subscribe((res) => {
                    this.initChartData(res);
                });
            this.subscriptions.push(sub);
        }
    }

    public toggleDataSet(items: Array<'warning' | 'danger' | 'critical'> = []) {
        this.chart.hideDataset(0, !items.includes('warning'));
        this.chart.hideDataset(1, !items.includes('danger'));
        this.chart.hideDataset(2, !items.includes('critical'));
    }

    toggleWarning(warning) {
        this.chart.hideDataset(0, !warning);
    }

    getMixedChartData(): any[] {
        if (!this.weekData) {
            return [
                { data: [], label: 'Warning', backgroundColor: '#faf66b', hoverBackgroundColor: '#FAF209', order: 1 },
                { data: [], label: 'Danger', backgroundColor: '#fcbf68', hoverBackgroundColor: '#FA9709', order: 1 },
                { data: [], label: 'Critical', backgroundColor: '#fa5f5f', hoverBackgroundColor: '#E40A0A', order: 1 },

                {
                    data: [],
                    label: 'Info',
                    type: 'line',
                    backgroundColor: 'rgba(54, 162, 235, 0.1)',
                    borderColor: '#0A97E480',
                    hoverBorderColor: '#0A97E4',
                    borderJoinStyle: 'round',
                    pointBorderColor: '#0A97E480',
                    pointBackgroundColor: '#0A97E4',
                    pointHoverBackgroundColor: 'rgba(54, 162, 235, 0.2)',
                    fill: true,
                    tension: 0.2,
                    order: 0,
                },
            ];
        }

        let warning = 0,
            danger = 0,
            critical = 0;
        for (const [key, value] of Object.entries(this.weekData)) {
            // console.log(key, value,'key value')
            warning += value.warning;
            danger += value.danger;
            critical += value.critical;
        }

        return [
            {
                data: [],
                label: `Warning (${warning})`,
                backgroundColor: '#faf66b',
                hoverBackgroundColor: '#FAF209',
                order: 1,
            },
            {
                data: [],
                label: `Danger (${danger})`,
                backgroundColor: '#fcbf68',
                hoverBackgroundColor: '#FA9709',
                order: 1,
            },
            {
                data: [],
                label: `Critical (${critical})`,
                backgroundColor: '#fa5f5f',
                hoverBackgroundColor: '#E40A0A',
                order: 1,
            },

            {
                data: [],
                label: 'Info',
                type: 'line',
                backgroundColor: 'rgba(54, 162, 235, 0.1)',
                borderColor: '#0A97E480',
                hoverBorderColor: '#0A97E4',
                borderJoinStyle: 'round',
                pointBorderColor: '#0A97E480',
                pointBackgroundColor: '#0A97E4',
                pointHoverBackgroundColor: 'rgba(54, 162, 235, 0.2)',
                fill: true,
                tension: 0.2,
                order: 0,
            },
        ];
    }

    ngOnDestroy() {
        for (const s of this.subscriptions) {
            s.unsubscribe();
        }
    }

    private initChartData(data: SeverityMap) {
        this.dataGroups = groupSummaryData(data, this.start_date, this.end_date);
        this.dataIndex = this.dataGroups.dates.length - 1;
        this.setBarChartData(this.dataIndex);
    }

    setBarChartData(index) {
        if (index >= 0 && index < this.dataGroups.dates.length) {
            this.dataIndex = index;
            this.barChartLabels = setDateLabelsInTimeFrame(this.dataGroups, index);
            this.mixedChartData = [
                { ...this.mixedChartData[0], data: this.dataGroups.warning, dates: this.dataGroups.dates },
                { ...this.mixedChartData[1], data: this.dataGroups.danger, dates: this.dataGroups.dates },
                { ...this.mixedChartData[2], data: this.dataGroups.critical, dates: this.dataGroups.dates },
                { ...this.mixedChartData[3], data: this.dataGroups.info, dates: this.dataGroups.dates },
            ];

            this.updateLineChartOptions();

            this.start_date = this.dataGroups.start_date;
            this.end_date = this.dataGroups.end_date;
        }
        this.loaded.emit(true);
        this.isLoaded = true;
    }

    updateLineChartOptions() {
        const infoData = this.mixedChartData[3].data;
        const points = [];
        for (let index = 0; index < infoData.length; index++) {
            infoData[index] === 0 ? points.push(0) : points.push(5);
        }
        this.mixedChartData[3].pointRadius = points;
    }
}
