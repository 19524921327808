import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './actions';
import { PwnedUser } from './model';

export const featureKey = 'pwnedUser';

export interface State extends EntityState<PwnedUser>, Status {}

export const adapter: EntityAdapter<PwnedUser> = createEntityAdapter<PwnedUser>({
    selectId: (entity) => entity.user_id,
});

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    on(actions.loadPwnedUsersSuccess, (state, { data }) => {
        state = { ...state, loaded: true };
        return adapter.setAll(data, state);
    }),
    on(actions.loadPwnedUsersFailure, (state, { error }) => ({ ...state, error }))
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
