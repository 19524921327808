<mat-hint color="warn" class="main-hint block" *ngIf="!policy?.id">
    There is no {{formValue?.displayName}}, when you click remediate you are going to create new compliance policy with
    the desired
    values of the baseline
</mat-hint>

<mat-error *ngIf="formErrors">
    Please fix the baseline errors
</mat-error>


<form [formGroup]="form" *ngIf="loaded">
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        Basics
                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status
                            [hasError]="!policy?.id || errorString.includes('/policy/description')"></sway-baseline-status>
                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td class="field">

                                Display Name
                            </td>

                            <td colspan="3">
                                {{formValue?.displayName}}
                            </td>
                        </tr>


                        <tr>
                            <td class="field">

                                Description
                            </td>

                            <td>
                                {{policy?.description}}
                            </td>

                            <td>
                                <mat-form-field appearance="outline">
                                    <textarea formControlName="description" matInput> </textarea>
                                </mat-form-field>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/description')"></sway-baseline-status>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>


    <div class="mb-6"></div>


    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        Email

                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status [hasError]="
                        !policy?.id ||
                        errorString.includes('/policy/managedEmailProfileRequired')"></sway-baseline-status>
                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr>
                            <td class="field">

                                Unable to set up email on the device

                                <mat-icon
                                    [matTooltip]="tooltips.managedEmailProfileRequired">info</mat-icon>
                            </td>

                            <td>
                                {{policy?.managedEmailProfileRequired ? 'Required' : 'Not Configured'}}
                            </td>

                            <td>
                                Not Configured

                                <mat-slide-toggle color="primary" formControlName="managedEmailProfileRequired">
                                    Required
                                </mat-slide-toggle>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/managedEmailProfileRequired')"></sway-baseline-status>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="mb-6"></div>




    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        Device Health

                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status [hasError]="
                        !policy?.id ||
                        errorString.includes('/policy/securityBlockJailbrokenDevices') ||
                        errorString.includes('/policy/deviceThreatProtectionRequiredSecurityLevel')

                        "></sway-baseline-status>
                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr>
                            <td class="field">
                                <div class="td-title">

                                    Jailbroken devices

                                    <mat-icon
                                    [matTooltip]="tooltips.securityBlockJailbrokenDevices">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.securityBlockJailbrokenDevices ? 'Block' : 'Not Configured'}}
                            </td>

                            <td>
                                Not Configured

                                <mat-slide-toggle color="primary" formControlName="securityBlockJailbrokenDevices">
                                    Required
                                </mat-slide-toggle>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/securityBlockJailbrokenDevices')"></sway-baseline-status>
                            </td>
                        </tr>






                        <tr>
                            <td class="field">
                                <div class="td-title">

                                Require the device to be at or under the Device Threat Level

                                <mat-icon
                                    [matTooltip]="tooltips.deviceThreatProtectionRequiredSecurityLevel">info</mat-icon>
                                    </div>
                            </td>

                            <td>
                                {{policy?.deviceThreatProtectionRequiredSecurityLevel ? 'Block' : 'Not Configured'}}
                            </td>

                            <td>
                                <mat-form-field appearance="outline">
                                    <mat-select
                                       formControlName="deviceThreatProtectionRequiredSecurityLevel">
                                        <mat-option value="notSet">Not Configured</mat-option>
                                        <mat-option value="secured">Secured</mat-option>
                                        <mat-option value="low">Low</mat-option>
                                        <mat-option value="medium">Medium</mat-option>
                                        <mat-option value="high">High</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/deviceThreatProtectionRequiredSecurityLevel')"></sway-baseline-status>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="mb-6"></div>









    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        Device Properties
                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status [hasError]="
                        !policy?.id ||

                        (errorString.includes('/policy/osMinimumVersion') ||
                        errorString.includes('/policy/osMaximumVersion') ||
                        errorString.includes('/policy/osMinimumBuildVersion') ||
                        errorString.includes('/policy/osMaximumBuildVersion')  )
                        "></sway-baseline-status>
                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td class="field">
                                <div class="td-title">

                                    Minimum OS version <mat-icon
                                        [matTooltip]="tooltips.osMinimumVersion">info</mat-icon>
                                </div>

                            </td>

                            <td>
                                {{policy?.osMinimumVersion || '-'}}
                            </td>

                            <td>
                                <mat-form-field appearance="outline" class="full-width">

                                    <input matInput formControlName="osMinimumVersion" />

                                    <mat-error *ngIf="form.get('osMinimumVersion').hasError('max')">Must be smaller than
                                        the maximum
                                        OS version</mat-error>
                                    <mat-error *ngIf="form.get('osMinimumVersion').hasError('pattern')">Must be a valid
                                        version
                                        format. Example: 4.3.06, 7.01.4, etc.</mat-error>
                                </mat-form-field>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/osMinimumVersion')"></sway-baseline-status>

                            </td>
                        </tr>



                        <tr>
                            <td class="field">
                                <div class="td-title">
                                    Maximum OS version<mat-icon [matTooltip]="tooltips.osMaximumVersion">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.osMaximumVersion || '-'}}
                            </td>

                            <td>
                                <mat-form-field appearance="outline" class="full-width">

                                    <input matInput formControlName="osMaximumVersion" />
                                    <mat-error *ngIf="form.get('osMaximumVersion').hasError('pattern')">Must be a valid
                                        version
                                        format. Example: 4.3.06, 7.01.4, etc.</mat-error>
                                    <mat-error *ngIf="form.get('osMaximumVersion').hasError('min')">Must be greater than
                                        the minimum
                                        OS version</mat-error>

                                </mat-form-field>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/osMaximumVersion')"></sway-baseline-status>

                            </td>
                        </tr>



                        <tr>
                            <td class="field">
                                <div class="td-title">
                                    Minimum OS build version
                                    <mat-icon [matTooltip]="tooltips.osMinimumBuildVersion">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.osMinimumBuildVersion || '-'}}
                            </td>

                            <td>
                                <mat-form-field class="full-width" appearance="outline">
                                    <input matInput formControlName="osMinimumBuildVersion" />
                                    <mat-error *ngIf="form.get('osMinimumBuildVersion').hasError('pattern')">Must be a valid build version format. Example: 18A291, 16G1114, 20E772520a</mat-error>
                                </mat-form-field>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/osMinimumBuildVersion')"></sway-baseline-status>

                            </td>
                        </tr>



                        <tr>
                            <td class="field">
                                <div class="td-title">
                                    Maximum OS build version<mat-icon
                                        [matTooltip]="tooltips.osMaximumBuildVersion">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.osMaximumBuildVersion || '-'}}
                            </td>

                            <td>
                                <mat-form-field class="full-width" appearance="outline">

                                    <input matInput formControlName="osMaximumBuildVersion" />

                                    <mat-error *ngIf="form.get('osMaximumBuildVersion').hasError('pattern')">Must be a valid build version format. Example: 18A291, 16G1114, 20E772520a</mat-error>
                                </mat-form-field>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/osMaximumBuildVersion')"></sway-baseline-status>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="mb-6"></div>


    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        Microsoft Defender for Endpoint


                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status
                            [hasError]="!policy?.id || errorString.includes('/policy/advancedThreatProtectionRequiredSecurityLevel')"
                        ></sway-baseline-status>
                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>


                        <tr>
                            <td class="field">
                                <div class="td-title">

                                    Require the device to be at or under the machine risk score:
                                    <mat-icon
                                        [matTooltip]="tooltips.advancedThreatProtectionRequiredSecurityLevel">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.advancedThreatProtectionRequiredSecurityLevel || 'Not Configured'}}
                            </td>

                            <td>
                                <mat-form-field appearance="outline">
                                    <mat-select formControlName="advancedThreatProtectionRequiredSecurityLevel">
                                        <mat-option value="notSet">Not Configured</mat-option>
                                        <mat-option value="unavailable">Clear</mat-option>
                                        <mat-option value="low">Low</mat-option>
                                        <mat-option value="medium">Medium</mat-option>
                                        <mat-option value="high">High</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/advancedThreatProtectionRequiredSecurityLevel')"></sway-baseline-status>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>


    <div class="mb-6"></div>


    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        System Security (Password)


                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status [hasError]="
                        !policy?.id ||

                            (errorString.includes('/policy/passcodeRequired')
                            || errorString.includes('/policy/passcodeBlockSimple')
                            || errorString.includes('/policy/passcodeMinimumLength')
                            || errorString.includes('/policy/passcodeRequiredType')
                            || errorString.includes('/policy/passcodeMinimumCharacterSetCount')
                            || errorString.includes('/policy/passcodeMinutesOfInactivityBeforeScreenTimeout')

                                || errorString.includes('/policy/passcodeMinutesOfInactivityBeforeLock')
                                || errorString.includes('/policy/passcodeExpirationDays')
                                || errorString.includes('/policy/passcodePreviousPasscodeBlockCount')
                                )

                        "></sway-baseline-status>
                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>


                        <tr>
                            <td class="field">
                                <div class="td-title">
                                    Require a password to unlock devices. <mat-icon
                                        [matTooltip]="tooltips.passcodeRequired">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.passcodeRequired ? 'Required' : 'Not Configured'}}
                            </td>

                            <td>
                                Not Configured

                                <mat-slide-toggle (change)="markFormAsDirty()" color="primary" formControlName="passcodeRequired">
                                    Required
                                </mat-slide-toggle>

                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/passcodeRequired!')"></sway-baseline-status>

                            </td>

                        </tr>



                        <ng-container *ngIf="passcodeRequired">

                            <tr>
                                <td class="field">
                                    <div class="td-title">

                                        Simple passwords

                                        <mat-icon [matTooltip]="tooltips.passcodeBlockSimple">info</mat-icon>

                                    </div>
                                </td>

                                <td>
                                    {{policy?.passcodeBlockSimple ? 'Required' : 'Not Configured'}}
                                </td>

                                <td>
                                    Not Configured

                                    <mat-slide-toggle [disabled]="!passcodeRequired" color="primary"
                                        formControlName="passcodeBlockSimple">
                                        Block
                                    </mat-slide-toggle>
                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passcodeRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passcodeBlockSimple')"></sway-baseline-status>

                                </td>

                            </tr>


                            <tr>
                                <td class="field">
                                    <div class="td-title">

                                        Minimum password length <mat-icon
                                            [matTooltip]="tooltips.passcodeMinimumLength">info</mat-icon>
                                    </div>
                                </td>

                                <td>
                                    {{policy?.passcodeMinimumLength || '-'}}
                                </td>

                                <td>
                                    <mat-form-field appearance="outline">

                                        <input [readonly]="!passcodeRequired" type="number" matInput
                                            formControlName="passcodeMinimumLength" />

                                        <mat-error
                                            *ngIf="form.get('passcodeMinimumLength').hasError('max') || form.get('passcodeMinimumLength').hasError('min')">
                                            The value must be between 4 and 14.
                                        </mat-error>


                                        <mat-error
                                            *ngIf="form.get('passcodeMinimumLength').hasError('required')">
                                            The value must be between 4 and 14.
                                        </mat-error>
                                    </mat-form-field>

                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passcodeRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passcodeMinimumLength')"></sway-baseline-status>

                                </td>

                            </tr>



                            <tr>
                                <td class="field">
                                    <div class="td-title">

                                        Required password type
                                        <mat-icon
                                            [matTooltip]="tooltips.passcodeRequiredType">info</mat-icon>
                                    </div>
                                </td>

                                <td>
                                    {{policy?.passcodeRequiredType || '-'}}
                                </td>

                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-select
                                            [disabled]="!passcodeRequired" formControlName="passcodeRequiredType">
                                            <mat-option value="deviceDefault">Not Configured</mat-option>
                                            <mat-option value="alphanumeric">Alphanumeric</mat-option>
                                            <mat-option value="numeric">Numeric</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passcodeRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passcodeRequiredType')"></sway-baseline-status>

                                </td>

                            </tr>




                            <tr>

                                <td class="field">
                                    <div class="td-title">

                                        Number of non-alphanumeric characters in password

                                        <mat-icon
                                            [matTooltip]="tooltips.passcodeMinimumCharacterSetCount">info</mat-icon>

                                    </div>
                                </td>

                                <td>
                                    {{ policy?.passcodeMinimumCharacterSetCount ?? 'Not configured' }}
                                </td>

                                <td>
                                    <mat-form-field appearance="outline">

                                        <mat-select [disabled]="!passcodeRequired"
                                            formControlName="passcodeMinimumCharacterSetCount">
                                            <mat-option [value]="'notConfigured'">Not configured</mat-option>
                                            <mat-option [value]="0">0</mat-option>
                                            <mat-option [value]="1">1</mat-option>
                                            <mat-option [value]="2">2</mat-option>
                                            <mat-option [value]="3">3</mat-option>
                                            <mat-option [value]="4">4</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passcodeRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passcodeMinimumCharacterSetCount')"></sway-baseline-status>

                                </td>

                            </tr>





                            <tr>

                                <td class="field">
                                    <div class="td-title">

                                        Maximum minutes after screen lock before password is required


                                        <mat-icon
                                            [matTooltip]="tooltips.passcodeMinutesOfInactivityBeforeScreenTimeout">info</mat-icon>

                                    </div>
                                </td>

                                <td>
                                    {{ policy?.passcodeMinutesOfInactivityBeforeScreenTimeout ?? 'Not configured' }}
                                </td>

                                <td>
                                    <mat-form-field appearance="outline">

                                        <mat-select [disabled]="!passcodeRequired"
                                            formControlName="passcodeMinutesOfInactivityBeforeScreenTimeout">
                                            <mat-option [value]="'notConfigured'">Not configured</mat-option>
                                            <mat-option [value]="0">Immediately</mat-option>
                                            <mat-option [value]="1">1 minute</mat-option>
                                            <mat-option [value]="5">5 minutes</mat-option>
                                            <mat-option [value]="15">15 minutes</mat-option>
                                            <mat-option [value]="60">1 hour</mat-option>
                                            <mat-option [value]="240">4 hours</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passcodeRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passcodeMinutesOfInactivityBeforeScreenTimeout')"></sway-baseline-status>

                                </td>

                            </tr>





                            <tr>
                                <td class="field">
                                    <div class="td-title">
                                        Maximum minutes of inactivity until screen locks
                                        <mat-icon
                                            [matTooltip]="tooltips.passcodeMinutesOfInactivityBeforeLock">info</mat-icon>
                                    </div>
                                </td>

                                <td>
                                    {{policy?.passcodeMinutesOfInactivityBeforeLock || 'Not Configured'}}
                                </td>

                                <td>

                                    <mat-form-field appearance="outline">

                                        <mat-select [disabled]="!passcodeRequired"
                                            formControlName="passcodeMinutesOfInactivityBeforeLock">
                                            <mat-option [value]="0">Not configured</mat-option>
                                            <mat-option [value]="1">1 minute</mat-option>
                                            <mat-option [value]="5">5 minutes</mat-option>
                                            <mat-option [value]="15">15 minutes</mat-option>
                                            <mat-option [value]="60">1 hour</mat-option>
                                            <mat-option [value]="240">4 hours</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passcodeRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passcodeMinutesOfInactivityBeforeLock')"></sway-baseline-status>

                                </td>

                            </tr>



                            <tr>
                                <td class="field">
                                    <div class="td-title">

                                        Password expiration (days) <mat-icon
                                            [matTooltip]="tooltips.passcodeExpirationDays">info</mat-icon>
                                    </div>
                                </td>

                                <td>
                                    {{policy?.passcodeExpirationDays || '-'}}
                                </td>

                                <td>
                                    <mat-form-field appearance="outline" class="full-width">
                                        <input [readonly]="!passcodeRequired" type="number" matInput
                                            formControlName="passcodeExpirationDays" />

                                        <mat-error
                                            *ngIf="form.get('passcodeExpirationDays').hasError('max') || form.get('passcodeExpirationDays').hasError('min')">
                                            The value must be between 1 and 65535.
                                        </mat-error>

                                        <mat-error
                                        *ngIf="form.get('passcodeExpirationDays').hasError('required')">
                                        The value must be between 1 and 65535.
                                    </mat-error>
                                    </mat-form-field>
                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passcodeRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passcodeExpirationDays')"></sway-baseline-status>

                                </td>

                            </tr>



                            <tr>
                                <td class="field">
                                    <div class="td-title">

                                        Number of previous passwords to prevent reuse <mat-icon
                                            matTooltip="This setting specifies the number of recent passwords that cannot be reused. Recommended value: 5 (1-24)">info</mat-icon>
                                    </div>
                                </td>

                                <td>
                                    {{policy?.passcodePreviousPasscodeBlockCount || '-'}}
                                </td>

                                <td>
                                    <mat-form-field appearance="outline" class="full-width">
                                        <input [readonly]="!passcodeRequired" type="number" matInput
                                            formControlName="passcodePreviousPasscodeBlockCount" />

                                        <mat-error
                                            *ngIf="form.get('passcodePreviousPasscodeBlockCount').hasError('max') || form.get('passcodePreviousPasscodeBlockCount').hasError('min')">

                                            The value must be between 1 and 24.
                                        </mat-error>


                                        <mat-error
                                            *ngIf="form.get('passcodePreviousPasscodeBlockCount').hasError('required')">

                                            The value must be between 1 and 24.
                                        </mat-error>
                                    </mat-form-field>
                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passcodeRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passcodePreviousPasscodeBlockCount')"></sway-baseline-status>
                                </td>

                            </tr>

                        </ng-container>

                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>



    <div class="mb-6"></div>


    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        Restricted Apps
                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status
                            [hasError]="!policy?.id || errorString.includes('/policy/restrictedApps')"
                        ></sway-baseline-status>
                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>


                        <tr>
                            <td class="field">
                                <div class="td-title">

                                    Restricted Apps
                                    <mat-icon [matTooltip]="tooltips.restrictedApps">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                <table *ngIf="policy?.restrictedApps?.length > 0 ">
                                    <thead>
                                        <tr>
                                            <th>Name </th>
                                            <th>App Id </th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        <tr *ngFor="let item of policy?.restrictedApps" class="os-item">
                                            <td>{{item.name}}</td>
                                            <td>{{item.appId}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>

                            <td formArrayName="restrictedApps">

                                <div *ngIf="!readOnly" class="button-wrapper">
                                    <button matTooltip="Add operation system range"
                                        (click)="addRestrictedAppRow()" mat-icon-button>
                                        <mat-icon color="primary">add_circle</mat-icon>
                                    </button>

                                </div>

                                <div *ngFor="let rangeItem of restrictedAppsControls; index as i"
                                    [formGroupName]="i">

                                    <div class="builds-wrapper">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Name</mat-label>
                                            <input formControlName="name" matInput>
                                        </mat-form-field>

                                        <mat-form-field class="min-version" appearance="outline">
                                            <mat-label>App Id</mat-label>
                                            <input formControlName="appId" matInput>
                                        </mat-form-field>

                                        <button *ngIf="!readOnly" (click)="removeRestrictedAppRow(i)"
                                            mat-icon-button>
                                            <mat-icon color="warn">delete</mat-icon>
                                        </button>
                                    </div>

                                    <div *ngIf="form.get('restrictedApps')?.get([i]).touched" class="errors">
                                        <mat-error
                                            *ngIf="form.get('restrictedApps')?.get([i]).get('name').errors || form.get('restrictedApps').get([i]).get('appId').errors">Must
                                            Name and app id are required</mat-error>
                                    </div>
                                </div>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/restrictedApps')"></sway-baseline-status>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>


    <div class="mb-6"></div>

    <app-compliance-policy-assignments [policy]="policy" [errorString]="errorString" [assignments]="assignments"
        [form]="form" [tenant_id]="tenant_id"> </app-compliance-policy-assignments>


    <div class="mb-6"></div>
</form>