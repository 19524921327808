import { AuditLogRecordType } from '../../interfaces/management/schema.interface';
import { Meta } from '../../interfaces/meta.interface';
import AzureActiveDirectoryStsLogon from './AzureActiveDirectoryStsLogon';
import AzureActiveDirectory from './AzureActiveDirectory';

const records: Partial<{ [key in AuditLogRecordType]: { [key: string]: Meta<any> } }> = {
    [AuditLogRecordType.AzureActiveDirectoryStsLogon]: AzureActiveDirectoryStsLogon,
    [AuditLogRecordType.AzureActiveDirectory]: AzureActiveDirectory
}

export default records;