import { Classification, Meta } from "../../interfaces/meta.interface";

type Fields = any;
function TIMailData_description(fields: TIMailDataDescFields) {
    return `A Malicious Mail named "${fields.Subject}" was intercepted.`
}


type TIMailDataDescFields = {
    P1Sender: string,
    P2Sender: string,
    SenderIp: string // Sender information
    Subject: string
    Recipients: string // Recipient information
}

const TIMailDataClx: Classification<any, TIMailDataDescFields>[] = [
    {
        severity: 'warning',
        title: 'Malicious Mail Intercepted',
        description: (fields: any) => TIMailData_description(fields)
    }
]

const TIMailData: Meta<any, TIMailDataDescFields> = {
    info: {
        title: 'Malicious Mail Intercepted',
        description: 'A Malicious Mail was intercepted.'
    },
    description_extractor: (log: any) => {
        return {
            P1Sender: log.P1Sender,
            P2Sender: log.P2Sender,
            SenderIp: log.SenderIp,
            Subject: log.Subject,
            Recipients: log.Recipients.join(', ')
        }
    },
    classifications: TIMailDataClx
}

const operations: { [key: string]: Meta<Fields> } = {
    'TIMailData': TIMailData,
}

export default operations;