import { DeviceCompliancePolicy, DeviceCompliancePolicyAssignment } from '@microsoft/microsoft-graph-types-beta';
import { createAction, props } from '@ngrx/store';
import { PolicyAssignment } from './model';

export const loadCompliancePolicies = createAction(
    '[Graph/Compliance Policies] Load Compliance Policy',
    props<{ _tenant: string }>(),
);

export const loadCompliancePoliciesSuccess = createAction(
    '[Graph/Compliance Policies] Load Compliance Policies Success',
    props<{ _tenant: string; data: PolicyAssignment[] }>(),
);

export const loadCompliancePoliciesFailure = createAction(
    '[Graph/Compliance Policies] Load Compliance Policy Failure',
    props<{ _tenant: string; error: any }>(),
);

export const reloadCompliancePolicies = createAction(
    '[Graph/Compliance Policies] Reload Compliance Policy',
    props<{ _tenant: string }>(),
);

export const reloadCompliancePoliciesSuccess = createAction(
    '[Graph/Compliance Policies] Reload Compliance Policies Success',
    props<{ _tenant: string; data: PolicyAssignment[] }>(),
);

export const reloadCompliancePoliciesFailure = createAction(
    '[Graph/Compliance Policies] Reload Compliance Policy Failure',
    props<{ _tenant: string; error: any }>(),
);

export const createCompliancePolicy = createAction(
    '[Graph/Compliance policies/] Create Compliance Policy ',
    props<{ _tenant: string; policy: DeviceCompliancePolicy; assignments?: DeviceCompliancePolicyAssignment[] }>(),
);

export const createCompliancePolicySuccess = createAction(
    '[Graph/Compliance Policies] Create Compliance Policy Success',
    props<{ _tenant: string; policy: PolicyAssignment }>(),
);

export const createCompliancePolicyFailure = createAction(
    '[Graph/Compliance Policies] Create Compliance Policy Failure',
    props<{ _tenant: string; error: any }>(),
);

export const updateCompliancePolicy = createAction(
    '[Graph/Compliance policies/] Update Compliance Policy ',
    props<{ _tenant: string; policy: any; assignments?: DeviceCompliancePolicyAssignment[] }>(),
);

export const updateCompliancePolicySuccess = createAction(
    '[Graph/Compliance Policies] Update Compliance Policy Success',
    props<{ _tenant: string; policy: PolicyAssignment }>(),
);

export const updateCompliancePolicyFailure = createAction(
    '[Graph/Compliance Policies] Update Compliance Policy Failure',
    props<{ _tenant: string; error: any }>(),
);

export const deleteCompliancePolicy = createAction(
    '[Graph/Compliance policies/] Delete Compliance Policy ',
    props<{ _tenant: string; id: string }>(),
);

export const deleteCompliancePolicySuccess = createAction(
    '[Graph/Compliance Policies] Delete Compliance Policy Success',
    props<{ _tenant: string; id: string }>(),
);

export const deleteCompliancePolicyFailure = createAction(
    '[Graph/Compliance Policies] Delete Compliance Policy Failure',
    props<{ _tenant: string; error: any }>(),
);
