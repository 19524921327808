import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of, catchError, concatMap, last, map, mergeMap, switchMap, filter, distinct, take } from 'rxjs';
import { retry } from 'src/app/pipes/retry.pipe';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { ChangesService } from 'src/app/services/changes.service';
import { client } from '../../..';
import { storeChangesToDB } from '../../../octiga/changes/actions';
import * as MailboxActions from './actions';
import { Mailbox } from './model';
import { skipUntilTenantLoaded } from 'src/app/services/blob.service';

const filterDiscoverySearchMailbox = map((mboxes: Array<Mailbox>) =>
    mboxes.filter((m) => !m.Name.startsWith('DiscoverySearchMailbox')),
);

@Injectable()
export class MailboxEffects {

    loadMailboxes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MailboxActions.fetchMailboxes),
            distinct((action) => action._tenant),
            skipUntilTenantLoaded(this.store),
            mergeMap((action) =>
                this.getPSMailboxes(action._tenant).pipe(
                    retry(3000, 3, 'exo timeout'),
                    last(),
                    map((mailboxes: Mailbox[]) => {
                        /* trim stmp:*/
                        mailboxes = mailboxes.map((m) => {
                            return {
                                ...m,
                                ForwardingSmtpAddress:
                                    m.ForwardingSmtpAddress !== null ? m.ForwardingSmtpAddress.substring(5) : null,
                            };
                        });
                        return MailboxActions.loadMailboxesSuccess({ _tenant: action._tenant, mailboxes });
                    }),
                    catchError((error) => of(MailboxActions.loadMailboxsFailure(error))),
                ),
            ),
        ),
    );

    updateMailboxFields$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MailboxActions.updateMailboxFields),
            mergeMap((action) => {
                const fields = Object.entries(action.data);
                const options = {};
                for (let i = 0; i < fields.length; i++) {
                    options[fields[i][0]] = fields[i][1];
                }
                return this.putPSMailbox(action._tenant, { Identity: action.Identity, options }).pipe(
                    map((_) =>
                        MailboxActions.updateMailboxFieldsSuccess({
                            _tenant: action._tenant,
                            Identity: action.Identity,
                            data: action.data,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            MailboxActions.updateMailboxFieldsFailure({
                                ...action,
                                error: { message: error.message, trace: error.trace },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    updateLitigationHold$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MailboxActions.updateMailboxLitigationHold),
            mergeMap((action) => {
                const fields = Object.entries(action.data);
                const options = {};
                for (let i = 0; i < fields.length; i++) {
                    options[fields[i][0]] = fields[i][1];
                }
                return this.putPSMailbox(action._tenant, { Identity: action.Identity, options }).pipe(
                    map((_) =>
                        MailboxActions.updateMailboxLitigationHoldSuccess({
                            _tenant: action._tenant,
                            Identity: action.Identity,
                            data: action.data,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            MailboxActions.updateMailboxLitigationHoldFailure({
                                ...action,
                                error: { message: error.message, trace: error.trace },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    deleteOWARule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MailboxActions.deleteOWARule),
            mergeMap((action) =>
                this.ajax
                    .delete(action._tenant, `/api/microsoft/powershell/exo/mailbox/${action.identity}/owaforward`)
                    .pipe(
                        concatMap(() => {
                            const params = { user: action.identity, fields: action.fields };
                            const item = this.changesService.formatChangesObjectToDB(params, action.changeType);
                            return [
                                storeChangesToDB({ _tenant: action._tenant, item }),
                                MailboxActions.updateMailbox({
                                    _tenant: action._tenant,
                                    mailbox: {
                                        id: action.identity,
                                        changes: {
                                            DeliverToMailboxAndForward: false,
                                            ForwardingAddress: null,
                                            ForwardingSmtpAddress: null,
                                        },
                                    },
                                }),
                                MailboxActions.deleteOWARuleSuccess(action),
                            ];
                        }),
                        catchError((error) =>
                            of(MailboxActions.deleteOWARuleFailure({ _tenant: action._tenant, error })),
                        ),
                    ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private changesService: ChangesService,
        private store: Store<any>,
    ) {}

    getPSMailboxes(tenant: string): Observable<Array<Mailbox>> {
        return this.ajax.get(tenant, '/api/microsoft/powershell/exo/mailbox').pipe(filterDiscoverySearchMailbox);
    }

    putPSMailbox(tenant: string, options: { [key: string]: any }): Observable<any> {
        const body = { ...options.options, Identity: options.Identity };
        return this.ajax.put(tenant, '/api/microsoft/powershell/exo/mailbox', body);
    }
}
