import {
    AuditLogRecordType,
    UserType,
    Workload,
} from './management/schema.interface'

import { Reputation } from './reputation.interface'

export type Severity = 'info' | 'warning' | 'danger' | 'critical'

export const WorkloadGuard: { [key in Workload]: boolean } = {
    Aip: true,
    AeD: true,
    AirInvestigation: true,
    ApplicationAudit: true,
    AttackSim: true,
    AzureActiveDirectory: true,
    Compliance: true,
    CRM: true,
    Dynamics365: true,
    Endpoint: true,
    Exchange: true,
    Kaizala: true,
    MicrosoftFlow: true,
    MicrosoftForms: true,
    MicrosoftStream: true,
    MicrosoftTeams: true,
    MyAnalytics: true,
    OneDrive: true,
    PowerApps: true,
    PowerBI: true,
    PrivacyInsights: true,
    PublicEndpoint: true,
    Quarantine: true,
    SecurityComplianceCenter: true,
    SharePoint: true,
    SkypeForBusiness: true,
    ThreatIntelligence: true,
    WorkplaceAnalytics: true,
    Yammer: true,
}

export type MicrosoftModules = {
    [key in Workload]: Partial<{
        [key in AuditLogRecordType]: {
            [key: string]: Meta<any>
        }
    }>
}

export abstract class Predicates {
    // isAllowed predicates check EventRules
    protected abstract isAllowedASN(event: MicrosoftEvent): boolean
    protected abstract isAllowedCountry(event: MicrosoftEvent): boolean
    protected abstract isAllowedRegion(event: MicrosoftEvent): boolean
    protected abstract isAllowedIP(event: MicrosoftEvent): boolean
    // all other predicates are checked if/when defined as part of event classification
    protected abstract isUserMemberOfOrganisation(event: MicrosoftEvent): boolean
    protected abstract isFraudulentIP(event: MicrosoftEvent): boolean
    protected abstract isForwardedOutsideOfOrg(event: MicrosoftEvent): boolean
    protected abstract isMicrosoftActivity(event: MicrosoftEvent): boolean
}

type FieldTypes = null | undefined | boolean | string | number

type Fields = {
    [key: string]: FieldTypes
} | null

export interface MicrosoftEvent<T = any, X = any> {
    id: string
    tenant: string
    user_id: string | null // actor
    object_id: string | null // target
    user_type: UserType
    source: 'MGMT' | 'UAL' | 'MAL'
    workload: Workload
    record_type: AuditLogRecordType
    operation: string
    timestamp: string
    ip_address: string | null
    reputation: Reputation | null
    fields: {
        [key in keyof T]: FieldTypes
    } | null
    description_fields?: {
        [key in keyof T]: FieldTypes
    } | null
    data: any
}

// see above Predicates class
export type PredicateKeys =
    | 'isAllowedCountry'
    | 'isAllowedRegion'
    | 'isAllowedASN'
    | 'isAllowedIP'
    | 'isUserMemberOfOrganisation'
    | 'isFraudulentIP'
    | 'isForwardedOutsideOfOrg'
    | 'isMicrosoftActivity';

export interface Classification<T = any, X = any> {
    description: string | ((fields: Partial<{ [key in keyof X]: FieldTypes }>) => string)
    severity: Severity
    title: string
    predicates?: Partial<{
        [key in PredicateKeys]: boolean
    }>
    fields?: Partial<{
        [key in keyof T]: Array<FieldTypes>
    }>
    description_fields?: Partial<{
        [key in keyof X]: FieldTypes
    }>
}

export interface Meta<T extends Fields = any, X extends Fields = any> {
    info: {
        title: string
        description: string
    }
    classifications?: Classification<T, X>[]
    extractor?: (data: any) => T
    description_extractor?: (data: any) => X
}

/**
 * Coordinate
 *
 * `lat: number` - latitude
 *
 * `lng: number` - longtitude
 */
export interface Coord {
    lat: number
    lng: number
}

export interface EventRule {
    id: string;
    msp_id: string;
    name: string;
    created: string;
    start: string | null;
    end: string | null;
    source: 'msp' | 'tenant';
    scope: 'tenant' | 'group' | 'user';
    // targets
    target_tenants: string[];
    target_groups: string[];
    target_users: {
        tenant_id: string;
        user_id: string;
        upn: string;
    }[];
    // inputs
    country: string[];
    asn: number[];
    ip: string[];
    geo: {
        center: Coord
        radius: number
    }[];
}