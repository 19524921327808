import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BaselineAudit_Entity } from '../baseline-audit';
import { ArrayTypeSchema, SchemaValue } from '../schema';

export interface MobileDeviceMailboxPolicy_SchemaData {
    Identity: SchemaValue<string>;
    PasswordHistory: SchemaValue<string>;
    PasswordExpiration: SchemaValue<string>;
    MinPasswordComplexCharacters: SchemaValue<string>;
    MinPasswordLength: SchemaValue<string>;
    MaxInactivityTimeLock: SchemaValue<string>;
    RequireDeviceEncryption: SchemaValue<boolean>;
    DeviceEncryptionEnabled: SchemaValue<boolean>;
    AlphanumericPasswordRequired: SchemaValue<boolean>;
    AllowSimplePassword: SchemaValue<boolean>;
    PasswordEnabled: SchemaValue<boolean>;
}

type MobileDeviceMailboxPolicy_Schema = ArrayTypeSchema<MobileDeviceMailboxPolicy_SchemaData>;
export type MobileDeviceMailboxPolicy_Deviation = BaselineAudit_Entity<MobileDeviceMailboxPolicy_Schema>;


export class MobileDeviceMailboxPolicySpec {
    static getMinutes(HHMMSSFormat: string): 'Unlimited' | number {
        if (HHMMSSFormat === 'Unlimited') {
            return HHMMSSFormat;
        } else if (HHMMSSFormat === '01:00:00') {
            return 60;
        } else {
            // get minutes from HH:MM:SS
            const minutesInString = HHMMSSFormat.substring(3, 5);
            // get rid of leading zeros
            return +minutesInString;
        }
    }

    static getDays(oldString: string): string {
        if (oldString === 'Unlimited') {
            return oldString;
        } else {
            return oldString.substring(0, oldString.indexOf('.'));
        }
    }

    static setTimeStamp(minutes): string {
        if (minutes === 'Unlimited') {
            return minutes;
        } else if (minutes === '60') {
            return '01:00:00';
        } else {
            if (+minutes < 10) {
                return `00:0${minutes}:00`;
            } else {
                return `00:${minutes}:00`;
            }
        }
    }

    static setDaysStamp(days): string {
        if (days === 'Unlimited') {
            return days;
        } else {
            return `${days}.00:00:00`;
        }
    }

    static maxValidatorAcceptUnlimitedString(maxValue: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value  = control.value;

            if(!value){
                return { max: true };
            }

          if( value === 'Unlimited'){
            return null;
          }


          if (value && !isNaN(Number(value))) {
            // Check if the numeric value exceeds maxValue
            const numValue = Number(value);
            if (numValue > maxValue) {
              return { max: true }; // Return error if the number is greater than maxValue
            }
        }
        return null
        };

      }


      static initForm(schema): FormGroup {
        const fb = new FormBuilder();
        return fb.group({
            Identity: [schema?.contains?.properties?.Identity?.const],
            PasswordHistory: [schema?.contains?.properties?.PasswordHistory?.const, [Validators.max(50)]],
            PasswordExpiration: [MobileDeviceMailboxPolicySpec.getDays(schema?.contains?.properties?.PasswordExpiration?.const) ,[MobileDeviceMailboxPolicySpec.maxValidatorAcceptUnlimitedString(730)]],
            MinPasswordComplexCharacters: [schema?.contains?.properties?.MinPasswordComplexCharacters?.const, [Validators.min(1), Validators.max(4)]],
            MinPasswordLength: [schema?.contains?.properties?.MinPasswordLength?.const, [Validators.min(1) , Validators.max(16)]],
            MaxInactivityTimeLock: [MobileDeviceMailboxPolicySpec.getMinutes(schema?.contains?.properties?.MaxInactivityTimeLock?.const), [MobileDeviceMailboxPolicySpec.maxValidatorAcceptUnlimitedString(60)]],
            RequireDeviceEncryption: [schema?.contains?.properties?.RequireDeviceEncryption?.const],
            DeviceEncryptionEnabled: [schema?.contains?.properties?.DeviceEncryptionEnabled?.const],
            AlphanumericPasswordRequired: [schema?.contains?.properties?.AlphanumericPasswordRequired?.const],
            AllowSimplePassword: [schema?.contains?.properties?.AllowSimplePassword?.const],
            PasswordEnabled: [schema?.contains?.properties?.PasswordEnabled?.const],
        });
    }

}