import { Meta } from "../../interfaces/meta.interface";
import { PageViewedClx } from '../SharePoint/SharePoint';

type PageViewedFields = {
    ObjectId: string
}

const PageViewed: Meta<PageViewedFields> = {
    info: {
        title: 'OneDrive Page Viewed',
        description: 'A page was viewed on a OneDrive site.',
    },
    extractor: (log: any) => {
        return {
            ObjectId: log.ObjectId
        }
    },
    classifications: PageViewedClx('OneDrive Page Viewed', 'A page was viewed on a OneDrive site.')
}

const operations = {
    'PageViewed': PageViewed
}

export default operations;