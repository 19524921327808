import { createAction, props } from '@ngrx/store';
import { MicrosoftEvent } from '@octiga/microsoft-events/src/interfaces/meta.interface';

export const updateEventsCursor = createAction(
    '[ETL/Events] Update Events Cursor',
    props<{ _tenant: string; date: string }>(),
);

export const fetchEvents = createAction(
    '[ETL/Events] Fetch All',
    props<{ _tenant: string; date: string; LastEvaluatedKey?: any }>(),
);

export const fetchEventsByIds = createAction(
    '[ETL/Events] Fetch Events by Batch Ids',
    props<{ _tenant: string; ids: string[] }>(),
);

export const fetchEventsByIdsSuccess = createAction(
    '[ETL/Events] etch Events by Batch Ids',
    props<{ _tenant: string; events: MicrosoftEvent[] }>(),
);

export interface IfetchEventsDelta {
    _tenant: string;
    start: string;
    end: string;
    LastEvaluatedKey?: any;
}
export const fetchEventsDelta = createAction('[ETL/Events] Fetch Delta', props<IfetchEventsDelta>());

export interface IfetchEventsDeltaSuccess {
    _tenant: string;
    start: string;
    end: string;
    events: MicrosoftEvent[];
    LastEvaluatedKey?: any;
}
export const fetchEventsDeltaSuccess = createAction(
    '[ETL/Events] Fetch Delta Success',
    props<IfetchEventsDeltaSuccess>(),
);

export interface IfetchEventsSuccess {
    _tenant: string;
    events: MicrosoftEvent[];
    date: string;
    LastEvaluatedKey?: any;
}
export const fetchEventsSuccess = createAction('[ETL/Events] Fetch All Success', props<IfetchEventsSuccess>());

export const fetchEventsByUser = createAction(
    '[ETL/Events] Fetch Events By User',
    props<{ _tenant: string; user_id: string; date: string; LastEvaluatedKey?: any }>(),
);

export interface IfetchEventsByUserSuccess {
    _tenant: string;
    events: MicrosoftEvent[];
    user_id: string;
    date: string;
    LastEvaluatedKey?: any;
}
export const fetchEventsByUserSuccess = createAction(
    '[ETL/Events] Fetch Events By User Success',
    props<IfetchEventsByUserSuccess>(),
);

export const fetchEvent = createAction('[ETL/Events] Fetch One', props<{ _tenant: string; id: string }>());

export const fetchEventSuccess = createAction(
    '[ETL/Events] Fetch One Success',
    props<{ _tenant: string; event: MicrosoftEvent }>(),
);
