import {
    createCompliancePolicy,
    updateCompliancePolicy,
} from 'src/app/stores/client/graph/compliance-policies/policies/actions';
import { PolicyAssignment } from 'src/app/stores/client/graph/compliance-policies/policies/model';
import { getCompliancePolicyAssignments } from '..';
import { MacOsCompliancePolicySchema } from 'src/app/modules/sway/tools/schemas/compliance-policies/mac-os-compliance-policy/model';

export function Remediate(_tenant: string, data: PolicyAssignment[], schema: MacOsCompliancePolicySchema) {
    const displayName = schema.contains.properties.policy.properties.displayName?.const;

    const currentPolicy = data.find((res) => res.policy?.displayName === displayName);

    const policy = {
        '@odata.type': '#microsoft.graph.macOSCompliancePolicy',
        displayName: schema.contains.properties.policy.properties.displayName?.const,
        description: schema.contains.properties.policy.properties.description?.const,

        // Device Health
        systemIntegrityProtectionEnabled:
            schema.contains.properties.policy.properties.systemIntegrityProtectionEnabled?.const, // boolean

        // Device Properties
        osMinimumVersion: schema.contains.properties.policy.properties.osMinimumVersion?.const || null, //string
        osMaximumVersion: schema.contains.properties.policy.properties.osMaximumVersion?.const || null, //string
        osMinimumBuildVersion: schema.contains.properties.policy.properties.osMinimumBuildVersion?.const || null, //string
        osMaximumBuildVersion: schema.contains.properties.policy.properties.osMaximumBuildVersion?.const || null, //string

        // System Security (Password)
        passwordRequired: schema.contains.properties.policy.properties.passwordRequired?.const, // boolean // check
        passwordBlockSimple: schema.contains.properties.policy.properties.passwordBlockSimple?.const, // boolean
        passwordRequiredType: schema.contains.properties.policy.properties.passwordRequiredType?.const, // string
        passwordMinimumLength: schema.contains.properties.policy.properties.passwordMinimumLength?.const, // number
        passwordMinimumCharacterSetCount:
            schema.contains.properties.policy.properties.passwordMinimumCharacterSetCount?.const ?? null,
        passwordMinutesOfInactivityBeforeLock:
            schema.contains.properties.policy.properties.passwordMinutesOfInactivityBeforeLock?.const || 0, // number
        passwordExpirationDays: schema.contains.properties.policy.properties.passwordExpirationDays?.const, // [Validators.min(1)?.const, Validators.max(225)]
        passwordPreviousPasswordBlockCount:
            schema.contains.properties.policy.properties.passwordPreviousPasswordBlockCount?.const,

        // Encryption
        storageRequireEncryption: schema.contains.properties.policy.properties.storageRequireEncryption?.const, // boolean

        firewallEnabled: schema.contains.properties.policy.properties.firewallEnabled?.const, //boolean
        firewallBlockAllIncoming: schema.contains.properties.policy.properties.firewallBlockAllIncoming?.const, //boolean
        firewallEnableStealthMode: schema.contains.properties.policy.properties.firewallEnableStealthMode?.const,
        gatekeeperAllowedAppSource: schema.contains.properties.policy.properties.gatekeeperAllowedAppSource?.const,
    };

    const assignments = getCompliancePolicyAssignments(schema);

    // need to update store
    if (currentPolicy?.id) {
        return updateCompliancePolicy({ _tenant, policy: { ...policy, id: currentPolicy.id }, assignments });
    } else {
        // const gracePeriodHours = schema.contains.properties.policy.properties.validOperatingSystemBuildRanges
        return createCompliancePolicy({
            _tenant,
            policy: {
                ...policy,

                scheduledActionsForRule: [
                    {
                        ruleName: 'DeviceNonCompliance',
                        scheduledActionConfigurations: [
                            {
                                actionType: 'block',
                                gracePeriodHours: 0, // 2 * 24 // convert days to hours
                            },
                        ],
                    },
                ],
            },
            assignments,
        });
    }
}
