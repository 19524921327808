import { createAction, props } from '@ngrx/store';
import { Category } from 'projects/oct-report-lib/src/public-api';

export const loadReportCategories = createAction('[Report/Sway Categories] Load');

export const loadReportCategoriesSuccess = createAction(
    '[Report/Sway Categories] Load Success',
    props<{ data: Category[] }>(),
);

export const loadReportCategoriesFailure = createAction(
    '[Report/Sway Categories] Load Failure',
    props<{ error: any }>(),
);
