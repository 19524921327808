import { AuditLogRecordType } from '../../interfaces/management/schema.interface';
import { Meta } from '../../interfaces/meta.interface';
import SharePoint from './SharePoint';
import SharePointFileOperation from './SharePointFileOperation';
import SharePointSharingOperation from './SharePointSharingOperation';

const records: Partial<{ [key in AuditLogRecordType]: { [key: string]: Meta<any> } }> = {
    [AuditLogRecordType.SharePoint]: SharePoint,
    [AuditLogRecordType.SharePointFileOperation]: SharePointFileOperation,
    [AuditLogRecordType.SharePointSharingOperation]: SharePointSharingOperation
}

export default records;