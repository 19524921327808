import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TargetGroups } from '../generic';
import { IOSCompliancePolicySchema } from './model';
import { AppListItem } from '@microsoft/microsoft-graph-types-beta';

export function initForm(schema: IOSCompliancePolicySchema, fb: FormBuilder): FormGroup {
    let form = new FormGroup({});
    const osRanges = schema.contains.properties.policy.properties.restrictedApps?.items?.oneOf;

    const restrictedApps =
        osRanges?.length > 0
            ? fb.array(
                osRanges?.map((res) =>
                    addRestrictedApp(fb, {
                        name: res.properties.name.const,
                        appId: res.properties.appId.const,
                    }),
                ) || [],
            )
            : fb.array([]);

    form = fb.group({
        displayName: [schema.contains.properties.policy.properties.displayName?.const, Validators.required],
        description: [schema.contains.properties.policy.properties.description?.const],

        managedEmailProfileRequired: [schema.contains.properties.policy.properties.managedEmailProfileRequired?.const], // boolean

        securityBlockJailbrokenDevices: [
            schema.contains.properties.policy.properties.securityBlockJailbrokenDevices?.const,
        ], // boolean

        deviceThreatProtectionRequiredSecurityLevel: [
            schema.contains.properties.policy.properties.deviceThreatProtectionRequiredSecurityLevel?.const,
        ],

        advancedThreatProtectionRequiredSecurityLevel: [
            schema.contains.properties.policy.properties.advancedThreatProtectionRequiredSecurityLevel?.const,
        ],

        // device properties
        osMinimumVersion: [
            schema.contains.properties.policy.properties.osMinimumVersion?.const,
            [
                Validators.pattern(/^\d{1,5}(?:\.\d{1,5}){0,3}$/),
                minmaxValidator(form.get('osMinimumVersion'), 'osMaximumVersion', 'max'),
            ],
        ], //string
        osMaximumVersion: [
            schema.contains.properties.policy.properties.osMaximumVersion?.const,
            [
                Validators.pattern(/^\d{1,5}(?:\.\d+){0,3}$/),
                minmaxValidator(form.get('osMaximumVersion'), 'osMinimumVersion', 'min'),
            ],
        ], //string
        osMinimumBuildVersion: [
            schema.contains.properties.policy.properties.osMinimumBuildVersion?.const,
            [
                Validators.pattern(/^\d{2}[A-Z]\d{3,6}[a-z]?$/),
                minmaxValidator(form.get('osMinimumBuildVersion'), 'osMaximumBuildVersion', 'max'),
            ],
        ], //string

        osMaximumBuildVersion: [
            schema.contains.properties.policy.properties.osMaximumBuildVersion?.const,
            [
                Validators.pattern(/^\d{2}[A-Z]\d{3,6}[a-z]?$/),
                minmaxValidator(form.get('osMaximumBuildVersion'), 'osMinimumBuildVersion', 'min'),
            ],
        ], //string

        // system security
        passcodeRequired: [schema.contains.properties.policy.properties.passcodeRequired?.const], // boolean // check
        passcodeBlockSimple: [schema.contains.properties.policy.properties.passcodeBlockSimple?.const], // boolean
        passcodeRequiredType: [schema.contains.properties.policy.properties.passcodeRequiredType?.const], // string
        passcodeMinimumLength: [
            schema.contains.properties.policy.properties.passcodeMinimumLength?.const,
            [Validators.min(4), Validators.max(14)],
        ], // number
        passcodeMinimumCharacterSetCount: [
            schema.contains.properties.policy.properties.passcodeMinimumCharacterSetCount?.const ?? 'notConfigured',
        ], // TODO

        passcodeMinutesOfInactivityBeforeScreenTimeout: [
            schema.contains.properties.policy.properties.passcodeMinutesOfInactivityBeforeScreenTimeout?.const,
        ], // TODO

        passcodeMinutesOfInactivityBeforeLock: [
            schema.contains.properties.policy.properties.passcodeMinutesOfInactivityBeforeLock?.const ?? 0,
        ], // number
        passcodeExpirationDays: [
            schema.contains.properties.policy.properties.passcodeExpirationDays?.const,
            [Validators.min(1), Validators.max(65535)],
        ], // [Validators.min(1), Validators.max(225)]
        passcodePreviousPasscodeBlockCount: [
            schema.contains.properties.policy.properties.passcodePreviousPasscodeBlockCount?.const,
            [Validators.min(1), Validators.max(24)],
        ], // number

        restrictedApps,

        assignments: fb.group({
            allUsers: [
                schema.contains.properties?.assignments?.items?.properties?.target?.oneOf?.some(
                    (res) => res?.properties?.['@odata.type']?.const === TargetGroups.allUsers,
                ),
            ],
            allDevices: [
                schema.contains.properties?.assignments?.items?.properties?.target?.oneOf?.some(
                    (res) => res?.properties?.['@odata.type']?.const === TargetGroups.allDevices,
                ),
            ],
            includedGroups: [getTargetGroupsIds(schema, TargetGroups.groupInclusion)],
            groupInclusion: [
                schema.contains.properties?.assignments?.items?.properties?.target?.oneOf?.some(
                    (res) => res?.properties?.['@odata.type']?.const === TargetGroups.groupInclusion,
                ),
            ],
        }),

        scheduledActionsForRule: fb.group({
            // TODO
            DeviceNonCompliance: fb.group({
                gracePeriodHours: [],
            }),
        }),
    });

    form.get('passcodeRequired')?.valueChanges.subscribe((ispasscodeRequired) => {
        const passcodeExpirationDaysControl = form.get('passcodeExpirationDays');
        const passcodeMinimumLengthControl = form.get('passcodeMinimumLength');
        const passcodePreviouspasscodeBlockCountControl = form.get('passcodePreviouspasscodeBlockCount');
        if (ispasscodeRequired) {
            passcodeExpirationDaysControl?.addValidators(Validators.required);
            passcodeMinimumLengthControl?.addValidators(Validators.required);
            passcodePreviouspasscodeBlockCountControl?.addValidators(Validators.required);
        } else {
            passcodeExpirationDaysControl?.removeValidators(Validators.required);
            passcodeMinimumLengthControl?.removeValidators(Validators.required);
            passcodePreviouspasscodeBlockCountControl?.removeValidators(Validators.required);
        }
        passcodeExpirationDaysControl?.updateValueAndValidity();
    });

    return form;
}

function addRestrictedApp(fb: FormBuilder, item?: AppListItem): FormGroup {
    return fb.group({
        name: [item?.name, [Validators.required]],
        appId: [item?.appId, [Validators.required]],
    });
}

export function getTargetGroupsIds(schema, type): string[] {
    const items =
        schema.contains.properties?.assignments?.items?.properties?.target?.oneOf?.filter(
            (res) => res?.properties?.['@odata.type']?.const === type,
        ) ?? [];
    const result = items?.map((res) => res.properties.groupId.const);

    return result;
}

export function minmaxValidator(control: AbstractControl, otherControlName: string, type: 'min' | 'max'): any {
    const validate = (control: AbstractControl) => {
        if (!control?.parent || !control) {
            return null;
        }

        const otherControlValue = control.parent.get(otherControlName).value;
        if (!control.value || !otherControlValue) {
            return null;
        }

        if (type === 'min') {
            if (getComparableNumber(control.value) < getComparableNumber(otherControlValue)) {
                return { min: { requiredMin: otherControlValue } };
            }
        } else {
            if (getComparableNumber(control.value) > getComparableNumber(otherControlValue)) {
                return { max: { requiredMin: otherControlValue } };
            }
        }

        return null;
    };

    return validate;
}

function getComparableNumber(value: string) {
    return +value.replaceAll('.', '');
}
