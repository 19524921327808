import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { Changes } from 'src/app/interfaces/changes.interface';
import { ChangesService } from 'src/app/services/changes.service';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as ChangesActions from './actions';

@Injectable()
export class ChangesEffects {
    fetchChangesByTimeRange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangesActions.fetchChanges),
            mergeMap((action) =>
                this.loadChanges(action._tenant, action.start, action.end).pipe(
                    map((changes: Changes[]) =>
                        ChangesActions.fetchChangesSuccess({
                            _tenant: action._tenant,
                            changes,
                            fetchedDates: this.changesService.listOfDates(action.start, action.end),
                        }),
                    ),
                    catchError((error) => of(ChangesActions.fetchChangesFailure({ _tenant: action._tenant, error }))),
                ),
            ),
        ),
    );

    putChangesItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChangesActions.storeChangesToDB),
            mergeMap((action) =>
                this.putChanges(action._tenant, action.item).pipe(
                    concatMap(() => [
                        ChangesActions.addChange({ _tenant: action._tenant, change: action.item }),
                        ChangesActions.storeChangesToDBSuccess({ _tenant: action._tenant, item: action.item }),
                    ]),
                    catchError((error) =>
                        of(ChangesActions.storeChangesToDBFailure({ _tenant: action._tenant, error })),
                    ),
                ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private changesService: ChangesService,
    ) {}

    loadChanges(_tenant: string, start: string, end: string): Observable<Changes[]> {
        return this.ajax.get(_tenant, `/api/octiga/v3/tenant/changes?start=${start}&end=${end}`);
    }

    putChanges(_tenant: string, item: Changes): Observable<any> {
        return this.ajax.put(_tenant, '/api/octiga/v3/tenant/changes', item);
    }
}
