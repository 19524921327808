export * from './Windows10CompliancePolicy.schema';
export * from './Windows10CompliancePolicy.form';
export const tooltips = {
    osMinimumVersion:
        'Select the oldest OS version a device can have. The operating system version is defined as major.minor.build.revision.',
    osMaximumVersion:
        'Select the newest OS version a device can have. The operating system version is defined as major.minor.build.revision.',
    mobileOsMinimumVersion:
        'Select the oldest OS version a mobile device can have. The operating system version is defined as major.minor.build.revision.',
    mobileOsMaximumVersion:
        'Select the newest OS version a mobile device can have. The operating system version is defined as major.minor.build.revision.',
    configurationManagerComplianceRequired:
        'Requires all compliance evaluations in Configuration Manager to be compliant. This is only applicable for co-managed devices running Windows 10 or later. All Intune-only devices will return N/A.',
    passwordRequired:
        'This setting specifies whether to require users to enter a password before access is granted to information on their mobile devices. Recommended value: Require',
    passwordBlockSimple:
        'This setting specifies whether to block mobile devices from using simple password sequences, such as 1234 or 1111. Recommended Value: Block',
};
