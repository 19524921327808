import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, distinct, map, mergeMap } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as actions from './actions';

@Injectable()
export class PwnedUserEffects {
    loadUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadPwnedUsers),
            distinct((action) => action._tenant),
            mergeMap(({ _tenant }) =>
                this.ajax.get(_tenant, '/api/octiga/v3/tenant/pwned-user').pipe(
                    map((data) => actions.loadPwnedUsersSuccess({ _tenant, data })),
                    catchError((error) => of(actions.loadPwnedUsersFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
    ) {}
}
