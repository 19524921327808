import { createAction, props } from '@ngrx/store';
import { CasMailbox } from './model';

export const fetchCasMailboxes = createAction('[Client/Pwsh/Exo] Fetch CasMailboxes', props<{ _tenant: string }>());

export const fetchCasMailboxesSuccess = createAction(
    '[Client/Pwsh/Exo] Fetch CasMailboxes Success',
    props<{ _tenant: string; data: CasMailbox[] }>(),
);

export const fetchCasMailboxesFailure = createAction(
    '[Client/Pwsh/Exo] Fetch CasMailboxes Failure',
    props<{ _tenant: string; error: any }>(),
);

export const updateCasMailbox = createAction(
    '[Client/Pwsh/Exo] Update CasMailbox',
    props<{ _tenant: string; data: Partial<CasMailbox> & { Identity: string }; field: string }>(),
);

export const updateCasMailboxEntityStatus = createAction(
    '[Client/Pwsh/Exo] Update CasMailbox Entity Status',
    props<{ _tenant: string; data: Partial<CasMailbox> & { Identity: string }; field: string }>(),
);

export const updateCasMailboxSuccess = createAction(
    '[Client/Pwsh/Exo] Update CasMailbox Success',
    props<{ _tenant: string; data: CasMailbox }>(),
);

export const updateCasMailboxFailure = createAction(
    '[Client/Pwsh/Exo] Update CasMailbox Failure',
    props<{ _tenant: string; data: Partial<CasMailbox> & { Identity: string }; field: string; error: any }>(),
);

export const deleteCasMailbox = createAction(
    '[Client/Pwsh/Exo] Delete CasMailbox',
    props<{ _tenant: string; id: string }>(),
);
