// https://docs.microsoft.com/en-us/office/office-365-management-api/office-365-management-activity-api-schema
// https://github.com/MicrosoftDocs/office-365-management-api/blob/master/office-365-management-api/office-365-management-activity-api-schema.md

interface TenantInfo {
    id: string
    name: string
}

interface Msp {
    id: string
    domain: string
    tenants: TenantInfo[]
}

export type Workload =
    'Aip' |
    'AeD' |
    'AirInvestigation' |
    'ApplicationAudit' |
    'AttackSim' |
    'AzureActiveDirectory' |
    'CRM' |
    'Compliance' |
    'Dynamics365' |
    'Endpoint'|
    'Exchange' |
    'Kaizala' |
    'MicrosoftFlow' |
    'MicrosoftForms' |
    'MicrosoftStream' |
    'MicrosoftTeams' |
    'MyAnalytics' |
    'OneDrive' |
    'PowerApps' |
    'PowerBI' |
    'PrivacyInsights' |
    'PublicEndpoint' |
    'Quarantine' |
    'SecurityComplianceCenter' |
    'SharePoint' |
    'SkypeForBusiness' |
    'ThreatIntelligence' |
    'WorkplaceAnalytics' |
    'Yammer'
    ;

export enum AuditLogRecordType {
    ExchangeAdmin = 1,
    ExchangeItem = 2,
    ExchangeItemGroup = 3,
    SharePoint = 4,
    SharePointFileOperation = 6,
    OneDrive = 7,
    AzureActiveDirectory = 8,
    AzureActiveDirectoryAccountLogon = 9,
    DataCenterSecurityCmdlet = 10,
    ComplianceDLPSharePoint = 11,
    ComplianceDLPExchange = 13,
    SharePointSharingOperation = 14,
    AzureActiveDirectoryStsLogon = 15,
    SkypeForBusinessPSTNUsage = 16,
    SkypeForBusinessUsersBlocked = 17,
    SecurityComplianceCenterEOPCmdlet = 18,
    ExchangeAggregatedOperation = 19,
    PowerBIAudit = 20,
    CRM = 21,
    Yammer = 22,
    SkypeForBusinessCmdlets = 23,
    Discovery = 24,
    MicrosoftTeams = 25,
    ThreatIntelligence = 28,
    MailSubmission = 29,
    MicrosoftFlow = 30,
    AeD = 31,
    MicrosoftStream = 32,
    ComplianceDLPSharePointClassification = 33,
    ThreatFinder = 34,
    Project = 35,
    SharePointListOperation = 36,
    SharePointCommentOperation = 37,
    DataGovernance = 38,
    Kaizala = 39,
    SecurityComplianceAlerts = 40,
    ThreatIntelligenceUrl = 41,
    SecurityComplianceInsights = 42,
    MIPLabel = 43,
    WorkplaceAnalytics = 44,
    PowerAppsApp = 45,
    PowerAppsPlan = 46,
    ThreatIntelligenceAtpContent = 47,
    LabelContentExplorer = 48,
    TeamsHealthcare = 49,
    ExchangeItemAggregated = 50,
    HygieneEvent = 51,
    DataInsightsRestApiAudit = 52,
    InformationBarrierPolicyApplication = 53,
    SharePointListItemOperation = 54,
    SharePointContentTypeOperation = 55,
    SharePointFieldOperation = 56,
    MicrosoftTeamsAdmin = 57,
    HRSignal = 58,
    MicrosoftTeamsDevice = 59,
    MicrosoftTeamsAnalytics = 60,
    InformationWorkerProtection = 61,
    Campaign = 62,
    DLPEndpoint = 63,
    AirInvestigation = 64,
    Quarantine = 65,
    MicrosoftForms = 66,
    ApplicationAudit = 67,
    ComplianceSupervisionExchange = 68,
    CustomerKeyServiceEncryption = 69,
    OfficeNative = 70,
    MipAutoLabelSharePointItem = 71,
    MipAutoLabelSharePointPolicyLocation = 72,
    MicrosoftTeamsShifts = 73,
    MipAutoLabelExchangeItem = 75,
    CortanaBriefing = 76,
    Search = 77,
    WDATPAlerts = 78,
    MDATPAudit = 81,
    SensitivityLabelPolicyMatch = 82,
    SensitivityLabelAction = 83,
    SensitivityLabeledFileAction = 84,
    AttackSim = 85,
    AirManualInvestigation = 86,
    SecurityComplianceRBAC = 87,
    UserTraining = 88,
    AirAdminActionInvestigation = 89,
    MSTIC = 90,
    PhysicalBadgingSignal = 91,
    AipDiscover = 93,
    AipSensitivityLabelAction = 94,
    AipProtectionAction = 95,
    AipFileDeleted = 96,
    AipHeartBeat = 97,
    MCASAlerts = 98,
    OnPremisesFileShareScannerDlp = 99,
    OnPremisesSharePointScannerDlp = 100,
    ExchangeSearch = 101,
    SharePointSearch = 102,
    PrivacyInsights = 103,
    MyAnalyticsSettings = 105,
    SecurityComplianceUserChange = 106,
    ComplianceDLPExchangeClassification = 107,
    MipExactDataMatch = 109
}

export enum UserType {
    Regular,
    Reserved,
    Admin,
    DcAdmin,
    System,
    Application,
    ServicePrincipal,
    CustomPolicy,
    SystemPolicy,
    Octiga = 99
}

export enum AuditLogScope {
    Online,
    Onprem
}

export interface Common {
    Id: string
    RecordType: AuditLogRecordType
    CreationTime: string
    Operation: string
    OrganizationId: string
    UserType: number
    UserKey: string
    Workload?: Workload
    ResultStatus?: string
    ObjectId?: string
    UserId: string
    ClientIP?: string // supposed to be mandatory. isn't
    Scope: AuditLogScope

    Version?: number // not listed in docs
    AppId?: string // not listed in docs
    ClientAppId?: string // not listed in docs
}
interface SharePointBase extends Common {
    Site?: string
}
interface SharePointFile extends Common {
    SiteUrl: string
    SourceFileName: string
}
interface SharePointSharing extends Common {
    TargetUserOrGroupName?: string
    TargetUserOrGroupType?: string
}
interface SharePoint extends Common {
    CustomEvent?: string
    EventData?: string
}
interface Project extends Common {
    CustomEvent?: string
    EventData?: string
}
interface ExchangeAdmin extends Common { }
interface ExchangeMailbox extends Common { }
interface AzureActiveDirectoryBase extends Common {
    ModifiedProperties?: Array<any>
}
interface AzureActiveDirectoryAccountLogon extends Common { }
interface AzureActiveDirectory extends Common { }
interface AzureActiveDirectorySecureTokenServiceLogon extends Common { }
interface DataLossPrevention extends Common {
    PolicyDetails: Array<any>
    SensitiveInfoDetectionIsIncluded: boolean
}

export type AuditRecord =
    Common |
    SharePointBase |
    SharePointFile |
    SharePointSharing |
    SharePoint |
    ExchangeAdmin |
    ExchangeMailbox |
    AzureActiveDirectoryBase |
    AzureActiveDirectoryAccountLogon |
    AzureActiveDirectory |
    AzureActiveDirectorySecureTokenServiceLogon |
    DataLossPrevention;
