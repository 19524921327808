import { Classification, Meta } from "../../interfaces/meta.interface";
import {
    allowed_asn,
    allowed_ip, fraud_ip_in_asn, fraud_ip_in_country, fraud_ip_in_ip, fraud_ip_in_region, fraud_ip_not_in_allowed_list, not_in_allowed_country, user_not_in_org
} from '../statements';

// LogonError types observed:

// UserStrongAuthEnrollmentRequiredInterrupt
// UserStrongAuthClientAuthNRequiredInterrupt
// UserAccountNotFound
// InvalidUserNameOrPassword
// SsoArtifactInvalidOrExpired
// KmsiInterrupt
// DelegationDoesNotExist
// None
type Fields = any;

type UserLoggedInFields = {
    LogonError: string
}

const login_success = 'A successful login occured.'
const may_indicate_an_attack = 'Many of these within a short period may indicate an attack.'

export function login_failed_clx(title: string, description: string): Classification<any>[] {
    return [
        // failed login: user in org. country not allowed. not allowed Region. not allowed IP.
        {
            severity: 'warning',
            title,
            description: `${description} ${not_in_allowed_country}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isUserMemberOfOrganisation: true
            }
        },
        // failed login: user not in org. country not allowed. not allowed Region. not allowed IP.
        {
            severity: 'info',
            title,
            description: `${description} ${user_not_in_org} ${not_in_allowed_country}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isUserMemberOfOrganisation: false,
            }
        },
        // failed login: user not in org. fraudulent IP. country not allowed. not allowed IP.
        {
            severity: 'info',
            title,
            description: `${description} ${user_not_in_org} ${fraud_ip_not_in_allowed_list}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: false
            }
        },
        // failed login: user not in org. fraudulent IP. country not allowed. allowed IP.
        {
            severity: 'info',
            title,
            description: `${description}  ${user_not_in_org} ${fraud_ip_in_ip}`,
            predicates: {
                isAllowedIP: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: false
            }
        },
        {
            severity: 'info',
            title,
            description: `${description}  ${user_not_in_org} ${fraud_ip_in_region}`,
            predicates: {
                isAllowedRegion: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: false
            }
        },
        // failed login: user not in org. fraudulent IP. in allowed countries
        {
            severity: 'info',
            title,
            description: `${description}  ${user_not_in_org} ${fraud_ip_in_country}`,
            predicates: {
                isAllowedCountry: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: false
            }
        },
         // failed login: user not in org. fraudulent IP. in allowed asn
         {
            severity: 'info',
            title,
            description: `${description}  ${user_not_in_org} ${fraud_ip_in_asn}`,
            predicates: {
                isAllowedASN: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: false
            }
        },
        // failed login: user not in org. country not allowed. not allowed IP
        {
            severity: 'info',
            title,
            description: `${description}  ${user_not_in_org} ${not_in_allowed_country}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isUserMemberOfOrganisation: false
            }
        },
        // failed login: user not in org. not allowed countries. allowed IP.
        {
            severity: 'info',
            title,
            description: `${description}  ${user_not_in_org} ${allowed_ip}`,
            predicates: {
                isAllowedIP: true,
                isMicrosoftActivity: false,
                isFraudulentIP: false,
                isUserMemberOfOrganisation: false
            }
        },
        // failed login: user not in org. not allowed countries. allowed IP.
        {
            severity: 'info',
            title,
            description: `${description}  ${user_not_in_org} ${allowed_asn}`,
            predicates: {
                isAllowedASN: true,
                isMicrosoftActivity: false,
                isFraudulentIP: false,
                isUserMemberOfOrganisation: false
            }
        },
        // failed login: user not in org.
        {
            severity: 'info',
            title,
            description: `${description} ${user_not_in_org}`,
            predicates: {
                isUserMemberOfOrganisation: false,
            }
        },
        // failed login
        {
            severity: 'info',
            title,
            description: `${description}`
        }
    ]
}


export function login_clx(title: string): Classification<any>[] {
    return [
        // successful login: fraudulent IP. user in org. country not allowed. ip not allowed. 
        {
            severity: 'critical',
            title,
            description: `${login_success} ${fraud_ip_not_in_allowed_list}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: true,
            }
        },
        // successful login: fraudulent IP. user not in org. country not allowed. ip not allowed. 
        {
            severity: 'info',
            title,
            description: `${login_success} ${user_not_in_org} ${fraud_ip_not_in_allowed_list}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: false,
            }
        },
        // successful login: country not allowed. user in org. region not allowed. ip not allowed.
        {
            severity: 'danger',
            title,
            description: `${login_success} ${not_in_allowed_country}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isUserMemberOfOrganisation: true,
            }
        },
        // successful login: country not allowed. user not in org. region not allowed. ip not allowed.
        {
            severity: 'info',
            title,
            description: `${login_success} ${user_not_in_org} ${not_in_allowed_country}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isUserMemberOfOrganisation: false,
            }
        },
        // successful login: fraudulent IP. user in org. country not allowed. ip allowed. 
        {
            severity: 'warning',
            title,
            description: `${login_success} ${fraud_ip_in_ip}`,
            predicates: {
                isAllowedIP: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: true
            }
        },
        // successful login: fraudulent IP. user not in org. country not allowed. ip allowed. 
        {
            severity: 'info',
            title,
            description: `${login_success} ${user_not_in_org} ${fraud_ip_in_ip}`,
            predicates: {
                isAllowedIP: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: false
            }
        },
        // successful login: fraudulent IP. user in org. in allowed region.
        {
            severity: 'warning',
            title,
            description: `${login_success} ${fraud_ip_in_region}`,
            predicates: {
                isAllowedRegion: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: true
            }
        },
        // successful login: fraudulent IP. user not in org. in allowed region.
        {
            severity: 'info',
            title,
            description: `${login_success} ${user_not_in_org} ${fraud_ip_in_region}`,
            predicates: {
                isAllowedRegion: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: false
            }
        },
         // successful login: fraudulent IP. user in org. in allowed ASN
         {
            severity: 'warning',
            title,
            description: `${login_success} ${fraud_ip_in_asn}`,
            predicates: {
                isAllowedASN: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: true
            }
        },
         // successful login: fraudulent IP. user not in org. in allowed ASN
         {
            severity: 'info',
            title,
            description: `${login_success} ${user_not_in_org} ${fraud_ip_in_asn}`,
            predicates: {
                isAllowedASN: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: false
            }
        },
        // successful login: fraudulent IP. user in org. in allowed countries
        {
            severity: 'warning',
            title,
            description: `${login_success} ${fraud_ip_in_country}`,
            predicates: {
                isAllowedCountry: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: true
            }
        },
        // successful login: fraudulent IP. user not in org. in allowed countries
        {
            severity: 'info',
            title,
            description: `${login_success} ${user_not_in_org} ${fraud_ip_in_country}`,
            predicates: {
                isAllowedCountry: true,
                isFraudulentIP: true,
                isUserMemberOfOrganisation: false
            }
        },
        // successful login
        {
            severity: 'info',
            title,
            description: `${login_success}`
        }
    ]
}

const UserLoggedIn: Meta = {
    info: {
        title: 'User Login',
        description: 'A user login event occured.',
    },
    classifications: login_clx('User Login')
}

const UserLoginFailed: Meta = {
    info: {
        title: 'User Login Failed',
        description: 'A failed login event occured.',
    },
    // extractor: (log: any) => {
    //     return {
    //         LogonError: !!log.LogonError? log.LogonError : null
    //     }
    // },
    description_extractor: (log: any) => {
        return {
            LogonError: !!log.LogonError ? log.LogonError : null
        }
    },
    classifications: login_failed_clx('User Login Failed', 'A failed login occured.')
}

const operations: { [key: string]: Meta<Fields> } = {
    UserLoggedIn,
    UserLoginFailed
}

export default operations;
