import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, distinct, expand, map, mergeMap, reduce } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as actions from './actions';
import { ServicePrincipal_AppRoleAssignments } from './model';

interface GraphSPResponse {
    value: [ServicePrincipal_AppRoleAssignments];
    '@odata.nextLink'?: string;
}

function parseToken(response: GraphSPResponse): string | false {
    let skiptoken: string | false = false;
    if (response['@odata.nextLink']) {
        skiptoken = response['@odata.nextLink'].split('skiptoken=')[1];
    }
    return skiptoken;
}

@Injectable()
export class GraphServicePrincipalEffects {
    private fetchServicePrincipalsWithPaging(tenant: string): Observable<ServicePrincipal_AppRoleAssignments[]> {
        const filter = '$filter=tags/any(t:t eq \'WindowsAzureActiveDirectoryIntegratedApp\')'; // anything that's not an official MS SP
        const expand_ = '$expand=appRoleAssignments'; // get permissions assigned to SP

        return this.ajax.get(tenant, `/api/microsoft/graph/servicePrincipals?${filter}&${expand_}`).pipe(
            expand((data: GraphSPResponse) => {
                const token = parseToken(data);
                if (token) {
                    return this.ajax.get(
                        tenant,
                        `/api/microsoft/graph/servicePrincipals?${filter}&${expand_}&$skiptoken=${token}`,
                    );
                } else {
                    return EMPTY;
                }
            }),
            reduce((acc, data: any) => {
                return acc.concat(data.value);
            }, []),
        );
    }

    getServicePrincipals$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadServicePrincipals),
            distinct((action) => action._tenant),
            mergeMap(({ _tenant }) =>
                this.fetchServicePrincipalsWithPaging(_tenant).pipe(
                    map((data: ServicePrincipal_AppRoleAssignments[]) =>
                        actions.loadServicePrincipalsSuccess({ _tenant, data }),
                    ),
                    catchError((error) => of(actions.loadServicePrincipalsFailure({ _tenant, error: error.message }))),
                ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private store: Store<any>,
    ) {}
}
