import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DirectoryObject, DirectoryRole, Domain, Group, MailFolder, User } from '@microsoft/microsoft-graph-types-beta';
import { Store } from '@ngrx/store';
import { catchError, filter, first, map, mergeMap, Observable, of, switchMap, take } from 'rxjs';
import { EXOInboxRule } from '../interfaces/powershell/exo/inbox-rule.interface';
import { client } from '../stores/client';
import { fetchGraphAuthenticationMethodsSuccess } from '../stores/client/graph/authentication/methods/actions';
import { AuthenticationMethodModel } from '../stores/client/graph/authentication/methods/model';
import { loadDirectoryRolesSuccess } from '../stores/client/graph/directory-roles/actions';
import {
    directoryRoleMembersLoaded,
    loadDirectoryRoleMembersSuccess,
} from '../stores/client/graph/directory-roles/members/actions';
import { DirectoryRoleMembersModel } from '../stores/client/graph/directory-roles/members/model';
import { loadDomainsSuccess } from '../stores/client/graph/domains/actions';
import { loadGroupsSuccess } from '../stores/client/graph/group/actions';
import { loadMembersSuccess, membersLoaded } from '../stores/client/graph/group/members/actions';
import { fetchExoInboxRuleSuccess, fetchGraphInboxRulesSuccess } from '../stores/client/graph/inbox-rules/actions';
import { InboxRuleModel } from '../stores/client/graph/inbox-rules/model';
import { loadGraphUsersSuccess } from '../stores/client/graph/user/user.actions';
import { fetchCasMailboxesSuccess } from '../stores/client/powershell/exo/cas-mailbox/actions';
import { CasMailbox } from '../stores/client/powershell/exo/cas-mailbox/model';
import { loadMailboxesSuccess } from '../stores/client/powershell/exo/mailbox/actions';
import { Mailbox } from '../stores/client/powershell/exo/mailbox/model';
import { MailboxFolderService } from './mailbox-folder.service';
import { Tenant } from '../interfaces/tenant.interface';

export function cacheEnabledForTenant(tenant: Tenant): boolean{
    return tenant.cache_enabled && tenant.beta_enabled;
}

function selectCacheEnabled<T extends { _tenant: string }>(store: Store, action: T) {
    return store.select(client(action._tenant).octiga.tenant.data).pipe(
        first((tenant) => !!tenant),
        map(cacheEnabledForTenant)
    )
}

export function skipUntilTenantLoaded<T extends { _tenant: string }>(store: Store) {
    return (source$: Observable<T>) => {
        return source$.pipe(
            mergeMap(action =>
                selectCacheEnabled(store, action).pipe(
                    take(1),
                    mergeMap(shouldCancel => {
                        if (shouldCancel) {
                            return of(null); // cancel the action
                        }
                        console.log(action)
                        return of(action); //  continue with the original action
                    }),
                    catchError(() => of(action)) // fallback to original action if condition observable errors
                )
            ),
            filter(action => action !== null) // filter out cancelled (null) action
        );
    };
}


// this is a workaround for missing types in typescript < 5.2.x
// this class can be removed once we're on typescript > 5.2.x
declare class DecompressionStream {
    constructor(format: "gzip" | "deflate");
    readonly readable: ReadableStream;
    readonly writable: WritableStream;
}

interface Blob {
    authMethods: AuthenticationMethodModel[];
    directoryRoleMembers: DirectoryRoleMembersModel[];
    directoryRoles: DirectoryRole[];
    domains: Domain[];
    groups: Group[];
    groupMembers: {
        [key: string]: DirectoryObject[];
    };
    mailboxes: Mailbox[];
    casMailboxes: CasMailbox[];
    messageRules: InboxRuleModel[];
    exoMessageRules: {
        user_id: string;
        rules: EXOInboxRule[];
    }[];
    users: User[];
    mailFolders: {
        user_id: string;
        folder_id: string;
        folder: MailFolder;
    }[];
}

function Base64ToArrayBuffer(base64: string) {
    const binary_string = atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes;
}

function DecodeBlob(data: string): Observable<Blob> {

    const promise = new Promise<Blob>(async (resolve, reject) => {
        const buff_array = Base64ToArrayBuffer(data);
        const readable = new ReadableStream()
        const compressedStream = new Response(buff_array).body;
        const unZipped = compressedStream.pipeThrough(new DecompressionStream('gzip'));
        const jsonString = await new Response(unZipped).text()
        const payload = JSON.parse(jsonString);
        resolve(payload)
    })

    return new Observable((subscriber) => {
        promise
            .then((value) => {
                subscriber.next(value);
                subscriber.complete();
            })
            .catch((error) => {
                subscriber.error(error);
            });
    });
}

@Injectable({
    providedIn: 'root',
})
export class BlobService {

    constructor(
        private http: HttpClient,
        private mailFolderService: MailboxFolderService
    ) { }

    public load(msp_id: string, tenant_id: string) {

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'msp-id': msp_id,
            'tenant-id': tenant_id,
        });

        return this.http.request('GET', '/api/sync/client/blob', { headers, responseType: 'text' })
            .pipe(
                switchMap((data) => DecodeBlob(data)),
                map((blob) => {
                    console.log(blob)
                    this.UpdateMailFoldersService(blob, tenant_id);
                    return this.GenerateActions(tenant_id, blob)
                }),
            )
    }

    private UpdateMailFoldersService({ mailFolders }, _tenant: string) {
        if (mailFolders) {
            for (const { user_id, folder_id, folder } of mailFolders) {
                this.mailFolderService.put(_tenant, user_id, folder_id, folder);
            }
        }
    }

    private GenerateActions(_tenant: string, data: Blob) {

        const {
            authMethods,
            directoryRoleMembers,
            directoryRoles,
            domains,
            groupMembers,
            groups,
            mailboxes,
            casMailboxes,
            messageRules,
            exoMessageRules,
            users,
            mailFolders,
        } = data;

        const actions = [];

        if (directoryRoleMembers) {
            const entries = Object.entries(directoryRoleMembers);
            for (const [id, item] of entries) {
                // note: these must be dispatched before loadDirectoryRolesSuccess to prevent their effect fetching
                actions.push(loadDirectoryRoleMembersSuccess({ _tenant, item }));
            }
            actions.push(directoryRoleMembersLoaded({ _tenant }));
        }

        if (users) {
            actions.push(loadGraphUsersSuccess({ _tenant, users }));
        }
        if (groups) actions.push(loadGroupsSuccess({ _tenant, groups }));
        if (directoryRoles)
            actions.push(loadDirectoryRolesSuccess({ _tenant, roles: directoryRoles }));
        if (domains) actions.push(loadDomainsSuccess({ _tenant, domains }));
        if (mailboxes) actions.push(loadMailboxesSuccess({ _tenant, mailboxes }));
        if (casMailboxes) actions.push(fetchCasMailboxesSuccess({ _tenant, data: casMailboxes }));
        if (authMethods)
            actions.push(fetchGraphAuthenticationMethodsSuccess({ _tenant, data: authMethods }));

        if (groupMembers) {
            const entries = Object.entries(groupMembers);
            for (const [id, members] of entries) {
                actions.push(loadMembersSuccess({ _tenant, id, members }));
            }
            actions.push(membersLoaded({ _tenant }));
        }

        if (messageRules) {
            actions.push(fetchGraphInboxRulesSuccess({ _tenant, rules: messageRules }));
        }

        if (exoMessageRules) {
            for (const { user_id, rules } of exoMessageRules) {
                for (const rule of rules) {
                    actions.push(fetchExoInboxRuleSuccess({ _tenant, user_id, rule }));
                }
            }
        }

        return actions;
    }
}
