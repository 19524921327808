import { Injectable } from '@angular/core';
import { DirectoryRole } from '@microsoft/microsoft-graph-types-beta';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, distinct, EMPTY, expand, filter, map, mergeMap, of, reduce, switchMap, take } from 'rxjs';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { client } from '../..';
import * as actions from './actions';
import { skipUntilTenantLoaded } from 'src/app/services/blob.service';

interface GraphResponse {
    '@odata.nextLink'?: string;
}

interface GraphDirectoryRoleResponse extends GraphResponse {
    value: DirectoryRole[];
}

function parseToken(response: GraphResponse): string | false {
    let skiptoken: string | false = false;
    if (response['@odata.nextLink']) {
        skiptoken = response['@odata.nextLink'].split('skiptoken=')[1];
    }
    return skiptoken;
}

@Injectable()
export class DirectoryRoleEffects {
    loadDirectoryRoles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadDirectoryRoles),
            distinct((action) => action._tenant),
            skipUntilTenantLoaded(this.store),
            mergeMap(({ _tenant }) =>
                this.get(_tenant).pipe(
                    map((roles) => actions.loadDirectoryRolesSuccess({ _tenant, roles })),
                    catchError((error: any) => of(actions.loadDirectoryRolesFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    private get(tenant: string) {
        const url = '/api/microsoft/graph/directoryRoles';
        return this.ajax.get<GraphDirectoryRoleResponse>(tenant, url).pipe(
            expand((data) => {
                const token = parseToken(data);
                if (token) {
                    return this.ajax.get<GraphDirectoryRoleResponse>(tenant, `${url}?$skiptoken=${token}`);
                } else {
                    return EMPTY;
                }
            }),
            reduce((acc, data) => acc.concat(data.value), [] as DirectoryRole[]),
        );
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private store: Store<any>,
    ) { }
}
