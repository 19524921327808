import { Injectable } from '@angular/core';
import { DeviceCompliancePolicyAssignment, DeviceCompliancePolicy } from '@microsoft/microsoft-graph-types-beta';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, concatMap, distinct, last, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { retry } from 'src/app/pipes/retry.pipe';
import * as actions from './actions';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { PolicyAssignment } from './model';
import { Store } from '@ngrx/store';

interface AssignmentsBody {
    assignments: DeviceCompliancePolicyAssignment[];
}

@Injectable()
export class CompliancePolicyEffect {
    loadCompliancePolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadCompliancePolicies),
            distinct((action) => action._tenant),
            mergeMap(({ _tenant }) =>
                this.get(_tenant).pipe(
                    retry(3000, 2, 'Compliance Policy policy timeout'),
                    last(),
                    map((data: any) => {
                        return actions.loadCompliancePoliciesSuccess({ _tenant, data });
                    }),
                    catchError((error) => of(actions.loadCompliancePoliciesFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    reloadCompliancePolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.reloadCompliancePolicies),
            mergeMap(({ _tenant }) =>
                this.get(_tenant).pipe(
                    retry(3000, 2, 'Compliance Policy policy timeout'),
                    last(),
                    map((data: any) => {
                        return actions.reloadCompliancePoliciesSuccess({ _tenant, data });
                    }),
                    catchError((error) => of(actions.reloadCompliancePoliciesFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    get(_tenant: string) {
        const getAssignments = (policy: DeviceCompliancePolicy): Observable<PolicyAssignment> => {
            return this.ajax
                .get<{
                    value: DeviceCompliancePolicyAssignment[];
                }>(_tenant, `/api/microsoft/graph/deviceManagement/deviceCompliancePolicies/${policy.id}/assignments`)
                .pipe(map((res) => ({ id: policy.id, policy, assignments: res.value })));
        };

        const policies$ = this.ajax.get<{ value: Array<DeviceCompliancePolicy> }>(
            _tenant,
            '/api/microsoft/graph/deviceManagement/deviceCompliancePolicies',
        );

        const policy_assignments$ = policies$.pipe(
            concatMap((res) => {
                return res.value?.length > 0 ? combineLatest(res.value.map((item) => getAssignments(item))) : of([]);
            }),
        );

        return policy_assignments$;
    }

    createCompliancePolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.createCompliancePolicy),
            mergeMap(({ _tenant, policy, assignments }) =>
                this.create(_tenant, policy, assignments).pipe(
                    map((policy: PolicyAssignment) => actions.createCompliancePolicySuccess({ _tenant, policy })),
                    tap(() => this.store.dispatch(actions.reloadCompliancePolicies({ _tenant }))),
                    catchError((error) => of(actions.createCompliancePolicyFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    create(_tenant: string, policy: Partial<DeviceCompliancePolicy>, assignments: DeviceCompliancePolicyAssignment[]) {
        return this.createCompliancePolicy(_tenant, policy).pipe(
            switchMap((policy) => this.createPolicyAssignments(_tenant, policy.id, assignments)),
        );
    }

    createCompliancePolicy(_tenant: string, policy: Partial<DeviceCompliancePolicy>) {
        return this.ajax.post<DeviceCompliancePolicy>(
            _tenant,
            '/api/microsoft/graph/deviceManagement/deviceCompliancePolicies',
            policy,
        );
    }

    createPolicyAssignments(_tenant: string, policyId: string, assignments: DeviceCompliancePolicyAssignment[]) {
        const body: AssignmentsBody = {
            assignments: assignments.map((res) => ({ ...res, id: policyId })),
        };

        return this.ajax.post<DeviceCompliancePolicy>(
            _tenant,
            `/api/microsoft/graph/deviceManagement/deviceCompliancePolicies/${policyId}/assign`,
            body,
        );
    }

    updateCompliancePolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateCompliancePolicy),
            mergeMap(({ _tenant, policy, assignments }) =>
                this.update(_tenant, policy, assignments)

                    .pipe(
                        last(),
                        map((res) => actions.updateCompliancePolicySuccess({ _tenant, policy: { ...policy } })),
                        tap(() => this.store.dispatch(actions.reloadCompliancePolicies({ _tenant }))),
                        catchError((error) => of(actions.updateCompliancePolicyFailure({ _tenant, error }))),
                    ),
            ),
        ),
    );

    update(_tenant: string, policy: DeviceCompliancePolicy, assignments: DeviceCompliancePolicyAssignment[]) {
        const request$ = this.ajax.patch<DeviceCompliancePolicy>(
            _tenant,
            '/api/microsoft/graph/deviceManagement/deviceCompliancePolicies/' + policy.id,
            policy,
        );
        const assignments$ = this.createPolicyAssignments(_tenant, policy.id, assignments);

        return combineLatest([request$, assignments$]).pipe(
            concatMap((res) =>
                this.ajax.get(_tenant, `/api/microsoft/graph/deviceManagement/deviceCompliancePolicies/${policy.id}`),
            ),
        );
    }

    deleteCompliancePolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteCompliancePolicy),
            mergeMap(({ _tenant, id }) =>
                this.ajax
                    .delete<string>(_tenant, '/api/microsoft/graph/deviceManagement/deviceCompliancePolicies/' + id)
                    .pipe(
                        retry(3000, 2, 'compliance policy timeout'),
                        last(),
                        map(() => actions.deleteCompliancePolicySuccess({ _tenant, id })),
                        catchError((error) => of(actions.deleteCompliancePolicyFailure({ _tenant, error }))),
                    ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private store: Store<any>,
    ) {}
}
