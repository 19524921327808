import { AuditLogRecordType } from '../../interfaces/management/schema.interface';
import { Meta } from '../../interfaces/meta.interface';

import ThreatIntelligence from './ThreatIntelligence';
import ThreatIntelligenceUrl from './ThreatIntelligenceUrl';
import ThreatIntelligenceApiContent from './ThreatIntelligenceAtpContent';

const records: Partial<{ [key in AuditLogRecordType]: { [key: string]: Meta<any> } }> = {
    [AuditLogRecordType.ThreatIntelligence]: ThreatIntelligence,
    [AuditLogRecordType.ThreatIntelligenceUrl]: ThreatIntelligenceUrl,
    [AuditLogRecordType.ThreatIntelligenceAtpContent]: ThreatIntelligenceApiContent
}

export default records;