<div class="wrapper">
   

    <ng-container *ngIf="!activation_lockout">
        <h3>
            <mat-icon>warning_outlined</mat-icon>
            {{ step === 1 ? 'Trial expired' : 'Trial expired - Upgrade Plan Request Sent' }}
        </h3>
        <div class="content">
            <p *ngIf="step === 1">Your trial has expired.Please upgrade your plan.</p>
            <p *ngIf="step === 2">Support will reach out to you shortly with subscription details. Please contact
                support@octiga.com, if you do not hear from us within 24 hours.</p>
        </div>

        <div class="confirm">
            <button mat-raised-button (click)="deleteSession()">Logout</button>
            <button mat-raised-button color="primary" *ngIf="step === 1" (click)="requestUpgrade()">Upgrade
                Plan</button>
        </div>
    </ng-container>


    <ng-container *ngIf="activation_lockout">
        <div class="mat-typology">
            <h3 class="font-bold">
                <mat-icon>warning_outlined</mat-icon>
                MSP Validation Required
            </h3>
        

            <p mat-dialog-content>
                The Octiga Team needs to validate your MSP/Service Provider before we can allow access to the Octiga
                portal.
            </p>

            <h3 style="margin-bottom: 1rem;" class="font-bold" mat-dialog-content>
                Please 
                
                <a href="mailto:support@octiga.com?subject=Account%20Validation">Email Octiga support</a>
                 from your business email address with the following information and we will activate your account.
            </h3>

            <ul>

                <li class="font-bold m-0"> 1- Your Website:</li>
                <li class="font-bold m-0"> 2- Contact Email Address (If different to sending address):</li>
                <li class="font-bold m-0"> 3- Phone Number (Optional):</li>
                
            </ul>

            <p style="margin-top: 1rem;">Thank you</p>
            <p>The Octiga Team</p>

            <div class="confirm">
                <button mat-raised-button (click)="deleteSession()">Logout</button>
                <button mat-raised-button color="primary" *ngIf="step === 1" (click)="requestUpgrade()">Upgrade
                    Plan</button>
            </div>
        </div>
    </ng-container>
</div>