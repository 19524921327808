export * from './form';
export * from './schema';
export const tooltips = {
    osMinimumVersion: 'Select the oldest OS version a device can have.',
    osMaximumVersion:
        'Select the newest OS version a device can have. It is recommended not to set a value for the maximum version to ensure compatibility with future OS updates.',
    osMinimumBuildVersion:
        'Enter the oldest OS build version a device can have. For example: 20E252 If you want to set an Apple Rapid Security Response update as the minimum OS build, enter the supplemental build version. For example: 20E772520a',
    osMaximumBuildVersion:
        'Enter the newest OS build version a device can have. For example: 20E252 If you want to set an Apple Rapid Security Response update as the maximum OS build, enter the supplemental build version. For example: 20E772520a. It is recommended not to set a value for the maximum version to ensure compatibility with future OS updates.',

    passwordRequired:
        'This setting specifies whether to require users to enter a password before access is granted to information on their devices. Recommended value: Require.',
    passwordBlockSimple:
        'This setting specifies whether to block devices from using simple password sequences, such as 1234 or 1111. Recommended Value: Block',
};
