import { createAction, props } from '@ngrx/store';
import { TicketCount } from 'projects/oct-report-lib/src/public-api';

export const loadPSATenantTicketCount = createAction(
    '[Report/Tenant Ticket Count] Load',
    props<{ _msp: string; _tenant: string; start?: string; end?: string }>(),
);

export const loadPSATenantTicketCountSuccess = createAction(
    '[Report/Tenant Ticket Count] Load Success',
    props<{ _msp: string; _tenant: string; data: TicketCount[] }>(),
);

export const loadPSATenantTicketCountFailure = createAction(
    '[Report/Tenant Ticket Count] Load Failure',
    props<{ _msp: string; _tenant: string; error: any }>(),
);
