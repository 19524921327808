import { Classification, Meta } from '../../interfaces/meta.interface';
import { fraud_ip, fraud_ip_not_in_allowed_list, not_in_allowed_ip } from "../statements";

const GetID = (log: any, key: string, type: number) => {
    if (log[key]) {
        let target = log[key].find((t: any) => t.Type === type);
        return target ? target.ID : undefined;
    }
}

const GetModifiedProperties = (log: any, name: string): any => {
    if (log.ModifiedProperties) {
        let foundProperties = log.ModifiedProperties.find((p: any) => p.Name === name);
        switch (name) {
            case 'Role.DisplayName':
                let roleName: string = foundProperties.NewValue;
                return roleName;
            case 'ConsentAction.Permissions': {
                if (foundProperties !== undefined) {
                    let consentActionPremissions: string = foundProperties.NewValue;
                    let str = consentActionPremissions.substring(consentActionPremissions.lastIndexOf('[[') + 2, consentActionPremissions.lastIndexOf(']') - 1);
                    if (str.includes('=>')) {
                        return undefined;
                    }
                    let str_array = str.split('], [');

                    let obj_array: { ClientId: string, ConsentType: string, Id: string, PrincipalId: string, ResourceId: string, Scope: string }[] = [];
                    str_array.forEach(s => {
                        var properties = s.split(', ');
                        var obj: any = {};
                        properties.forEach(p => {
                            var pair = p.split(': ');
                            obj[pair[0]] = pair[1];
                        });
                        obj_array.push(obj);
                    })
                    var result = obj_array.map(item => {
                        return ({ ResourceId: item.ResourceId, Scope: item.Scope });
                    })
                    return result;
                } else {
                    return undefined;
                }
            }
        }
    }
}

type AppConsentDescFields = {
    /** Application Name */
    appName: string
    /** Actor User ID */
    user: string
}

const app_consent_description = (fields: AppConsentDescFields) => {
    return `The following application '${fields.appName}' was consented.`
}

function AppConsentClx(title: string): Classification<any, AppConsentDescFields>[] {
    return [
        // consent to application.
        {
            severity: 'danger',
            title,
            description: (fields: any) => app_consent_description(fields),
            fields: {
                ResultStatus: ['Success'],
            },
        },
        {
            severity: 'warning',
            title,
            description: (fields: any) => app_consent_description(fields),
            fields: {
              ResultStatus: ['Failure'],
            },
          },
    ]
}

const ConsentToApplication: Meta<any, AppConsentDescFields> = {
    info: {
        title: 'Consent Granted For App',
        description: 'A User granted consent for an Azure App to access you tenant.',

    },
    description_extractor: (log: any) => {
        return {
            appName: GetID(log, 'Target', 1),
            user: GetID(log, 'Actor', 5),
            consentActionPremissions: GetModifiedProperties(log, 'ConsentAction.Permissions') as Array<{ ResourceId: string, Scope: string }> | string | undefined
        }
    },
    extractor: (log: any) => {
        return {
            ResultStatus: log.ResultStatus
        }
    },
    classifications: AppConsentClx('Consent Granted For App')
}

type AddMemberToRoleDescFields = {
    /** user upn */
    user: string
    service_principal: string
    /** role name */
    roleName: string
}

const add_member_to_role_description = (fields: AddMemberToRoleDescFields) => {
    if (!!fields.user) {
        return `A User ${fields.user} was added to a Role Group ${fields.roleName}.`
    } else {
        return `A Service Principal ${fields.service_principal} was added to a Role Group ${fields.roleName}.`;
    }
}

function AddMemberToRoleClx(title: string): Classification<any, AddMemberToRoleDescFields>[] {
    return [
        // add member to role: fraudulent IP. not in allowed list.
        {
            severity: 'critical',
            title,
            description: (fields: any) => add_member_to_role_description(fields) + fraud_ip_not_in_allowed_list,
            predicates: {
                isFraudulentIP: true,
                isAllowedASN: false,
                isAllowedCountry: false,
                isAllowedRegion: false,
                isAllowedIP: false
            }
        },
        // add member to role: not in allowed list.
        {
            severity: 'danger',
            title,
            description: (fields: any) => add_member_to_role_description(fields) + not_in_allowed_ip,
            predicates: {
                isAllowedASN: false,
                isAllowedCountry: false,
                isAllowedRegion: false,
                isAllowedIP: false
            }
        },
        // add member to role: fraud IP.
        {
            severity: 'warning',
            title,
            description: (fields: any) => add_member_to_role_description(fields) + fraud_ip,
            predicates: {
                isFraudulentIP: true
            }
        },
        // add member to role.
        {
            severity: 'warning',
            title,
            description: (fields: any) => add_member_to_role_description(fields),
        }
    ]
}

const AddMemberToRole: Meta<any, AddMemberToRoleDescFields> = {
    info: {
        title: 'User Added to Role Group',
        description: 'A User was added to a Role Group.',
    },
    description_extractor: (log: any) => {
        return {
            user: GetID(log, 'Target', 5),
            service_principal: GetID(log, 'Target', 2),
            roleName: GetModifiedProperties(log, 'Role.DisplayName')
        }
    },
    classifications: AddMemberToRoleClx('User Added to Role Group')
}

const AddUser: Meta<any> = {
    info: {
        title: 'New User Created',
        description: 'A new user was created on your tenant.',
    }
}

const change_user_license_description = (fields: { user: string }) => {
    return `User License was changed on ${fields.user}.`;
}

const ChangeUserLicenseClx: Classification<any, { user: string }>[] = [
    {
        severity: 'warning',
        title: 'User License Changed',
        description: (fields: any) => change_user_license_description(fields)
    }
]

const ChangeUserLicense: Meta<any> = {
    info: {
        title: 'User License Changed',
        description: 'A User License has changed.'
    },
    description_extractor: (log: any) => {
        return {
            user: GetID(log, 'Target', 5)
        }
    },
    classifications: ChangeUserLicenseClx
}

const operations = {
    'Consent to application.': ConsentToApplication,
    'Add member to role.': AddMemberToRole,
    'Add user.': AddUser,
    'Change user license.': ChangeUserLicense
}

export default operations;
