import {
    ActionReducerMap,
    createFeatureSelector,
    createSelector,
    DefaultProjectorFn,
    MemoizedSelector,
    MetaReducer,
} from '@ngrx/store';
import { selectStatus } from 'src/app/stores/status.interface';
import * as fromMspAsn from './asn/reducer';
import * as fromInputGeo from './event-rules/input-geo/reducer';
import * as fromEventRules from './event-rules/reducer';
import { Geo } from './geo/model';
import * as fromMspGeo from './geo/reducer';
import * as fromMspCWMBoards from './integrations/cwm/boards/reducer';
import * as fromMspCWMStatuses from './integrations/cwm/boards/statuses/reducer';
import * as fromMspCWMTeams from './integrations/cwm/boards/teams/reducer';
import * as fromMspCWMTypes from './integrations/cwm/boards/types/reducer';
import * as fromMspCWMSubTypes from './integrations/cwm/boards/types/subTypes/reducer';
import * as fromMspCWMCompanies from './integrations/cwm/companies/reducer';
import * as fromMspCWMConnection from './integrations/cwm/connection/reducer';
import * as fromMspCWMPriorities from './integrations/cwm/priorities/reducer';
import * as fromMspCWMSeverities from './integrations/cwm/severities/reducer';
import * as fromMspDatto from './integrations/datto/reducer';
import * as fromMspHalo from './integrations/halo/reducer';
import * as fromMspIp from './ip/reducer';
import {
    mspAccessGroupsFeatureKey,
    mspAccessGroupsReducer,
    MspAccessGroupsState,
} from './msp-access-groups/msp-access-groups.reducer';
import * as fromMspGraphUser from './msp-graph-user/reducer';
import * as fromMspUser from './msp-user/reducer';
import * as fromMsp from './msp/reducer';
import { EventRule } from './event-rules/model';

export const featureKey = 'msp';

export interface State {
    [mspAccessGroupsFeatureKey]: MspAccessGroupsState;
    [fromMsp.featureKey]: fromMsp.State;
    [fromMspUser.featureKey]: fromMspUser.State;
    [fromMspGraphUser.featureKey]: fromMspGraphUser.State;
    [fromMspHalo.featureKey]: fromMspHalo.State;
    [fromMspDatto.featureKey]: fromMspDatto.MspDattoState;
    [fromMspCWMConnection.featureKey]: fromMspCWMConnection.State;
    [fromMspCWMBoards.featureKey]: fromMspCWMBoards.State;
    [fromMspCWMPriorities.featureKey]: fromMspCWMPriorities.State;
    [fromMspCWMSeverities.featureKey]: fromMspCWMSeverities.State;
    [fromMspCWMStatuses.featureKey]: fromMspCWMStatuses.State;
    [fromMspCWMTeams.featureKey]: fromMspCWMTeams.State;
    [fromMspCWMTypes.featureKey]: fromMspCWMTypes.State;
    [fromMspCWMSubTypes.featureKey]: fromMspCWMSubTypes.State;
    [fromMspCWMCompanies.featureKey]: fromMspCWMCompanies.State;
    [fromEventRules.featureKey]: fromEventRules.State;
    [fromMspAsn.featureKey]: fromMspAsn.State;
    [fromMspIp.featureKey]: fromMspIp.State;
    [fromMspGeo.featureKey]: fromMspGeo.State;
    [fromInputGeo.featureKey]: fromInputGeo.State;
}

export const reducers: ActionReducerMap<State> = {
    [mspAccessGroupsFeatureKey]: mspAccessGroupsReducer,
    [fromMsp.featureKey]: fromMsp.reducer,
    [fromMspUser.featureKey]: fromMspUser.reducer,
    [fromMspGraphUser.featureKey]: fromMspGraphUser.reducer,
    [fromMspHalo.featureKey]: fromMspHalo.reducer,
    [fromMspDatto.featureKey]: fromMspDatto.reducer,
    [fromMspCWMConnection.featureKey]: fromMspCWMConnection.reducer,
    [fromMspCWMBoards.featureKey]: fromMspCWMBoards.reducer,
    [fromMspCWMPriorities.featureKey]: fromMspCWMPriorities.reducer,
    [fromMspCWMSeverities.featureKey]: fromMspCWMSeverities.reducer,
    [fromMspCWMStatuses.featureKey]: fromMspCWMStatuses.reducer,
    [fromMspCWMTeams.featureKey]: fromMspCWMTeams.reducer,
    [fromMspCWMTypes.featureKey]: fromMspCWMTypes.reducer,
    [fromMspCWMSubTypes.featureKey]: fromMspCWMSubTypes.reducer,
    [fromMspCWMCompanies.featureKey]: fromMspCWMCompanies.reducer,
    [fromEventRules.featureKey]: fromEventRules.reducer,
    [fromMspAsn.featureKey]: fromMspAsn.reducer,
    [fromMspIp.featureKey]: fromMspIp.reducer,
    [fromMspGeo.featureKey]: fromMspGeo.reducer,
    [fromInputGeo.featureKey]: fromInputGeo.reducer,
};

export const metaReducers: MetaReducer<State>[] = [];

export const selectFeature = createFeatureSelector<State>(featureKey);

const selectMsp = createSelector(selectFeature, (state) => state[fromMsp.featureKey]);
export const selectMspData = createSelector(selectMsp, fromMsp.selectData);
export const selectMspRootUser = createSelector(selectMspData, (res) => res?.root_user);

export const selectMspLoaded = createSelector(selectMsp, fromMsp.selectLoaded); // TODO: refactor to selectMspStatus
export const selectMspLoading = createSelector(selectMsp, fromMsp.selectLoading); // TODO: refactor to selectMspStatus
export const selectMspUpdating = createSelector(selectMsp, fromMsp.selectUpdating); // TODO: refactor to selectMspStatus
export const selectMspError = createSelector(selectMsp, fromMsp.selectError); // TODO: refactor to selectMspStatus
export const selectMspStatus = createSelector(selectMsp, selectStatus);

export const selectShowStonlyTrial = createSelector(selectMsp, (state) => state.showStonlyTrial);

const selectMspUser = createSelector(selectFeature, (state) => state[fromMspUser.featureKey]);
export const selectMspUsers = createSelector(selectMspUser, fromMspUser.selectAll);
export const selectMspUsersStatus = createSelector(selectMspUser, selectStatus);

const selectMspGraphUser = createSelector(selectFeature, (state) => state[fromMspGraphUser.featureKey]);
export const selectMspGraphMspUsers = createSelector(selectMspGraphUser, fromMspGraphUser.selectAll);
export const selectMspGraphUsersStatus = createSelector(selectMspUser, selectStatus);

const selectMspCWMConnection = createSelector(selectFeature, (state) => state[fromMspCWMConnection.featureKey]);
export const selectMspCWMConnectionData = createSelector(selectMspCWMConnection, fromMspCWMConnection.selectData);

const selectMspCWMCompanies = createSelector(selectFeature, (state) => state[fromMspCWMCompanies.featureKey]);
export const selectMspCWMCompaniesData = createSelector(selectMspCWMCompanies, fromMspCWMCompanies.selectData);
export const selectMspCWMCompaniesStatus = createSelector(selectMspCWMCompanies, selectStatus);

const selectMspCWMBoards = createSelector(selectFeature, (state) => state[fromMspCWMBoards.featureKey]);
export const selectMspCWMBoardsData = createSelector(selectMspCWMBoards, fromMspCWMBoards.selectData);
export const selectMspCWMBoardsStatus = createSelector(selectMspCWMBoards, selectStatus);

const selectMspCWMStatuses = createSelector(selectFeature, (state) => state[fromMspCWMStatuses.featureKey]);
export const selectMspCWMStatusesByBoardId = (id: number) =>
    createSelector(createSelector(selectMspCWMStatuses, fromMspCWMStatuses.selectAll), (statuses) => {
        const found = statuses.find((s) => s.board_id === id);
        return !!found ? found.data : [];
    });
export const selectMspCWMStatusesTotal = createSelector(selectMspCWMStatuses, fromMspCWMStatuses.selectTotal);
export const selectMspCWMStatusesStatus = createSelector(selectMspCWMStatuses, selectStatus);

const selectMspCWMPriorities = createSelector(selectFeature, (state) => state[fromMspCWMPriorities.featureKey]);
export const selectMspCWMPrioritiesData = createSelector(selectMspCWMPriorities, fromMspCWMPriorities.selectData);
export const selectMspCWMPrioritiesStatus = createSelector(selectMspCWMPriorities, selectStatus);

const selectMspCWMSeverities = createSelector(selectFeature, (state) => state[fromMspCWMSeverities.featureKey]);
export const selectMspCWMSeveritiesData = createSelector(selectMspCWMSeverities, fromMspCWMSeverities.selectData);
export const selectMspCWMSeveritiesStatus = createSelector(selectMspCWMSeverities, selectStatus);

const selectMspCWMTeams = createSelector(selectFeature, (state) => state[fromMspCWMTeams.featureKey]);
export const selectMspCWMTeamsByBoardId = (id: number): MemoizedSelector<object, any, DefaultProjectorFn<any>> =>
    createSelector(createSelector(selectMspCWMTeams, fromMspCWMTeams.selectAll), (teams) => {
        const found = teams.find((s) => s.board_id === id);
        return !!found ? found.data : [];
    });
export const selectMspCWMTeamsTotal = createSelector(selectMspCWMTeams, fromMspCWMTeams.selectTotal);
export const selectMspCWMTeamsStatus = createSelector(selectMspCWMTeams, selectStatus);

const selectMspCWMTypes = createSelector(selectFeature, (state) => state[fromMspCWMTypes.featureKey]);

export const selectMspCWMTypesByBoardId = (id: number) =>
    createSelector(createSelector(selectMspCWMTypes, fromMspCWMTypes.selectAll), (types) => {
        const found = types.find((t) => t.board_id === id);
        return !!found ? found.data : [];
    });

export const selectMspCWMTypesStatus = createSelector(selectMspCWMTypes, selectStatus);
const selectMspCWMSubTypes = createSelector(selectFeature, (state) => state[fromMspCWMSubTypes.featureKey]);

export const selectMspCWMSubTypesByBoardTypeId = (board_id: number, type_id: number) =>
    createSelector(createSelector(selectMspCWMSubTypes, fromMspCWMSubTypes.selectAll), (subTypes) => {
        const found = subTypes.find((t) => t.board_id === board_id && t.type_id === type_id);
        return !!found ? found.data : [];
    });
export const selectMspCWMSubTypesStatus = createSelector(selectMspCWMSubTypes, selectStatus);

const selectMspHalo = createSelector(selectFeature, (state) => state[fromMspHalo.featureKey]);
export const selectMspHaloConfigStatus = createSelector(selectMspHalo, selectStatus);
export const selectMspHaloConfigData = createSelector(selectMspHalo, fromMspHalo.selectData);
export const selectMspHaloConnection = createSelector(
    selectMspHaloConfigData,
    ({ connectionCredentials }) => connectionCredentials,
);

const selectMspDatto = createSelector(selectFeature, (state) => state[fromMspDatto.featureKey]);
export const selectMspDattoConfigStatus = createSelector(selectMspDatto, selectStatus);
export const selectMspDattoConfigData = createSelector(selectMspDatto, fromMspDatto.selectData);
export const selectMspDattoConnection = createSelector(
    selectMspDattoConfigData,
    ({ connectionCredentials }) => connectionCredentials,
);

const selectEventRules = createSelector(selectFeature, (state) => state[fromEventRules.featureKey]);
export const selectEventRulesAll = createSelector(selectEventRules, fromEventRules.selectAll);
export const selectEventRulesEntities = createSelector(selectEventRules, fromEventRules.selectEntities);
export const selectEventRulesStatus = createSelector(selectEventRules, selectStatus);
export const selectEventRuleById = (id: string): MemoizedSelector<object, EventRule, DefaultProjectorFn<EventRule>> =>
    createSelector(selectEventRules, fromEventRules.selectEntity(id));

export const selectEventRulesForTenantTargets = (tenant_id: string) =>
    createSelector(selectEventRulesAll, (rules) =>
        rules.filter((rule) => rule.target_tenants.some((t) => t === tenant_id)),
    );

export const selectEventRulesForUserTargets = (tenant_id: string) =>
    createSelector(selectEventRulesAll, (rules) =>
        rules.filter((rule) => rule.target_users.some((user) => user.tenant_id === tenant_id)),
    );

const selectMspAsn = createSelector(selectFeature, (state) => state[fromMspAsn.featureKey]);
export const selectMspASNs = createSelector(selectMspAsn, fromMspAsn.selectAll);
export const selectMspASNsStatus = createSelector(selectMspAsn, selectStatus);
export const selectMspASNsTotal = createSelector(selectMspAsn, fromMspAsn.selectTotal);

const selectMspIp = createSelector(selectFeature, (state) => state[fromMspIp.featureKey]);
export const selectMspIps = createSelector(selectMspIp, fromMspIp.selectAll);
export const selectMspIpsStatus = createSelector(selectMspIp, selectStatus);
export const selectMspIpsTotal = createSelector(selectMspIp, fromMspIp.selectTotal);

const selectMspGeo = createSelector(selectFeature, (state) => state[fromMspGeo.featureKey]);
export const selectMspGeos = createSelector(selectMspGeo, fromMspGeo.selectAll);
export const selectMspGeosStatus = createSelector(selectMspGeo, selectStatus);
export const selectMspGeosTotal = createSelector(selectMspGeo, fromMspGeo.selectTotal);

const selectInputGeo = createSelector(selectFeature, (state) => state[fromInputGeo.featureKey]);
export const selectInputGeoAll = createSelector(selectInputGeo, fromInputGeo.selectAll);

export const selectGeosForTenant = (tenant_id: string) =>
    createSelector(
        selectEventRulesForTenantTargets(tenant_id),
        selectMspGeos,
        selectInputGeoAll,
        (rules, geos, inputs) => {
            // all geos used by tenant_id (once each)
            const results = new Map<string, Geo>();
            for (const rule of rules) {
                for (const input of inputs) {
                    if (input.event_rule_id === rule.id) {
                        results.set(input.geo_id, geos[input.geo_id]);
                    }
                }
            }
            return [...results.values()];
        },
    );
