import { MicrosoftModules } from '../interfaces/meta.interface';
import AzureActiveDirectory from './AzureActiveDirectory';
import Aip from './Aip';
import Compliance from './Compliance';
import CRM from './CRM';
import Exchange from './Exchange';
import MicrosoftTeams from './MicrosoftTeams';
import OneDrive from './OneDrive';
import PowerApps from './PowerApps';
import PowerBI from './PowerBI';
import Quarantine from './Quarantine';
import SecurityComplianceCenter from './SecurityComplianceCenter';
import SharePoint from './SharePoint';
import SkypeForBusiness from './SkypeForBusiness';
import ThreatIntelligence from './ThreatIntelligence';

const x: MicrosoftModules = {
    Aip,
    AeD: {},
    AirInvestigation: {},
    ApplicationAudit: {},
    AttackSim: {},
    AzureActiveDirectory,
    CRM,
    Compliance,
    Dynamics365: {},
    Endpoint: {},
    Exchange,
    Kaizala: {},
    MicrosoftFlow: {},
    MicrosoftForms: {},
    MicrosoftTeams,
    MicrosoftStream: {},
    MyAnalytics: {},
    OneDrive,
    PowerApps,
    PowerBI,
    PrivacyInsights: {},
    PublicEndpoint: {},
    Quarantine,
    SecurityComplianceCenter,
    SharePoint,
    SkypeForBusiness,
    ThreatIntelligence,
    WorkplaceAnalytics: {},
    Yammer: {}
}

export default x;