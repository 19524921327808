import { Domain, User } from '@microsoft/microsoft-graph-types-beta';
import {
    Classification,
    EventRule,
    MicrosoftEvent,
    PredicateKeys,
    Predicates,
} from '../interfaces/meta.interface';
import workloads from '../workloads';
import { isIPInNetwork, isIPPublic, isPointInCircle, isSystemUser } from './util';

function DefaultClassification(event: MicrosoftEvent): Classification {
    return {
        title: `${event.workload}: ${event.operation}`,
        description: `${event.workload}: ${event.operation}`,
        severity: 'info',
    };
}

function ClassificationNotDefined(event: MicrosoftEvent): boolean {
    return (
        !workloads[event.workload] ||
        !workloads[event.workload][event.record_type] ||
        !workloads[event.workload][event.record_type]![event.operation]
    );
}

function isRuleActive(rule: EventRule, event_timestamp: string): boolean {

    const has_start = rule.start !== null;
    const has_end = rule.end !== null;

    if (!has_start && !has_end) {
        return true;
    }

    const start = has_start ? rule.start! : '0000';
    const end = has_end ? rule.end! : '9999';

    return (event_timestamp >= start && event_timestamp <= end);
}

export class Classifier extends Predicates {

    private tenant_rules: EventRule[] = [];
    private user_rules: Map<string, EventRule[]> = new Map();

    constructor(
        public readonly tenant_id: string,
        private app_id: string,
        private users: User[],
        private domains: Domain[],
        rules: EventRule[]
    ) {
        super()
        console.log({ version: '4.1.6' })
        this.setRules(rules);
    }

    /**
     * Sorts rules by scope ('user' or 'tenant')
     * user rules are mapped by user UPN for faster comparison against events
     * @param rules
     */
    private setRules(rules: EventRule[]) {

        for (const rule of rules) {

            const forTenant = rule.target_tenants.some(id => id === this.tenant_id);

            if (forTenant) {
                this.tenant_rules.push(rule);
                continue;
            }

            const tenantUsers = rule.target_users.filter(({ tenant_id }) => tenant_id === this.tenant_id);

            for (const { upn } of tenantUsers) {
                const _upn = upn.toLowerCase();
                const previous = this.user_rules.get(_upn) ?? [];
                this.user_rules.set(_upn, [...previous, rule])
            }
        }
    }

    public run(event: MicrosoftEvent): Classification {

        event = { ...event }; // make a copy to workaround readonly properties from ngrx

        if (event.user_id){
            event.user_id = event.user_id.toLowerCase();
        }

        if (isSystemUser(event.user_type)) {
            // if it's a system event
            return DefaultClassification(event);
        }

        if (ClassificationNotDefined(event)) {
            // if we don't have a classification defined
            return DefaultClassification(event);
        }

        if (event.ip_address && !isIPPublic(event.ip_address)) {
            // isn't a public IP
            return DefaultClassification(event);
        }

        // TODO: not sure why we have this '2023-02-17' date check, but can probably be removed after 2024-02-17
        if (
            event.user_id?.includes(this.app_id) &&
            event.timestamp.split('T')[0] >= '2023-02-17'
        ) {
            // octiga app performed events
            return DefaultClassification(event);
        }

        if (event.user_id?.substring(0, 13) === 'urn:spo:anon#') {
            // sharepoint anon event
            // TODO: investigate the semantics of these spo events further
            return DefaultClassification(event);
        }

        // we have a definition for this operation
        const operation = workloads[event.workload][event.record_type]![event.operation];

        if (!operation.classifications) {
            // it doesn't have any classifications, just info
            // TODO: remove this construct in favour of a simple 'info' classification
            return {
                title: operation.info.title,
                description: operation.info.description,
                severity: 'info',
            };
        }

        let fields = event.fields as any; // TODO: fix interfaces
        let found: Classification | null = null;

        for (let i = 0; i < operation.classifications.length; i++) {
            const clx = operation.classifications[i];
            let all_fns_true = true;
            let all_flds_match = true;

            // console.log(event.id, `${i}`, clx.description)

            // check predicate functions
            if (clx.predicates) {
                let fns = Object.entries(clx.predicates);
                try {
                    all_fns_true = fns.every(([fn_name, fn_result]) => {
                        const result = this[fn_name as PredicateKeys](event)
                        // console.log(event.id, `${i}`, fn_name, 'expected:', fn_result, 'actual:', result)
                        return (fn_result === result)
                    });
                } catch (err) {
                    console.error(err);
                }
            }

            // console.log(event.id, `${i}`, all_fns_true ? 'Match' : 'No Match')

            // check fields
            if (clx.fields) {
                let flds = Object.entries(clx.fields);
                all_flds_match = flds.every(([fld_name, fld_result]) => {
                    return (
                        fld_result === undefined ||
                        fld_result.includes(fields?.[fld_name])
                    );
                });
            }

            // check both predicates and fields are true
            if (all_fns_true && all_flds_match) {
                found = clx;
                break;
            }
        }

        if (found === null) {
            return DefaultClassification(event);
        } else {
            return found;
        }
    }

    protected isAllowedASN(event: MicrosoftEvent): boolean {

        function checkRule(rule: EventRule, event: MicrosoftEvent) {
            const ruleAllowsAsn = rule.asn.some(asn => asn === event.reputation!.asn);
            return ruleAllowsAsn && isRuleActive(rule, event.timestamp);
        }

        // no data to check
        if (event.reputation === null) {
            return true;
        }

        // check tenant level rules
        for (const rule of this.tenant_rules) {
            if (checkRule(rule, event)) {
                return true
            }
        }

        // check user specfic rules
        if (event.user_id && this.user_rules.has(event.user_id)) {
            for (const rule of this.user_rules.get(event.user_id)!) {
                if (checkRule(rule, event)) {
                    return true
                }
            }
        }

        return false;
    }


    protected isAllowedCountry(event: MicrosoftEvent): boolean {

        function checkRule(rule: EventRule, event: MicrosoftEvent) {
            const ruleAllowsCountry = rule.country.some(cc => cc === event.reputation!.country_code);
            return ruleAllowsCountry && isRuleActive(rule, event.timestamp);
        }

        // no data to check
        if (event.reputation === null) {
            return true;
        }

        // check tenant level rules
        for (const rule of this.tenant_rules) {
            if (checkRule(rule, event)) {
                return true
            }
        }

        // check user specfic rules
        if (event.user_id && this.user_rules.has(event.user_id)) {
            for (const rule of this.user_rules.get(event.user_id)!) {
                if (checkRule(rule, event)) {
                    return true
                }
            }
        }

        return false;
    }

    protected isAllowedRegion(event: MicrosoftEvent): boolean {

        function checkRule(rule: EventRule, event: MicrosoftEvent) {
            const ruleAllowsRegion = rule.geo.some(({ center, radius }) =>
                isPointInCircle(
                    { lat: event.reputation!.latitude, lng: event.reputation!.longitude },
                    center,
                    radius
                )
            );
            return ruleAllowsRegion && isRuleActive(rule, event.timestamp)
        }

        // no data to check
        if (event.reputation === null) {
            return true;
        }

        // check tenant level rules
        for (const rule of this.tenant_rules) {
            if (checkRule(rule, event)) {
                return true
            }
        }

        // check user specfic rules
        if (event.user_id && this.user_rules.has(event.user_id)) {
            for (const rule of this.user_rules.get(event.user_id)!) {
                if (checkRule(rule, event)) {
                    return true
                }
            }
        }

        return false;
    }

    protected isAllowedIP(event: MicrosoftEvent): boolean {

        function checkRule(rule: EventRule, event: MicrosoftEvent) {
            const ruleAllowsIp = rule.ip.some(ip => isIPInNetwork(event.ip_address!, ip));
            return ruleAllowsIp && isRuleActive(rule, event.timestamp)
        }

        // no data to check
        if (event.reputation === null) {
            return true;
        }

        // check tenant level rules
        for (const rule of this.tenant_rules) {
            if (checkRule(rule, event)) {
                return true
            }
        }

        // check user specfic rules
        if (event.user_id && this.user_rules.has(event.user_id)) {
            for (const rule of this.user_rules.get(event.user_id)!) {
                if (checkRule(rule, event)) {
                    return true
                }
            }
        }

        return false;

    }

    protected isFraudulentIP(event: MicrosoftEvent): boolean {
        return false;
        // if (event.ip_address === null)
        //     return false;
        // let ipqs = event.ipqs;
        // if (ipqs !== undefined) {
        //     const region_allowList = this.allowList.filter(item => (item.type === 'coord' && item.active));
        //     const country_allowList = this.allowList.filter(item => item.type === 'country');
        //     const foundRegion = region_allowList.find(item => {
        //         return isPointInCircle({ lat: ipqs!.latitude!, lng: ipqs!.longitude! }, { lat: item.center!.lat, lng: item.center!.lng }, item.radius!)
        //     })

        //     const foundCountry = country_allowList.find(item => item.country_code === ipqs!.country_code);

        //     if (foundRegion && foundRegion.bypass.fraudIP) {
        //         return false;
        //     }
        //     else if (foundCountry && foundCountry.bypass.fraudIP) {
        //         return false;
        //     }
        //     else if (ipqs && ipqs.fraud_score) { // ip_address.ipqs can be null if IPv6, etc.
        //         return ipqs.fraud_score > 75;
        //     }
        //     else {
        //         return false;
        //     }
        // }
        // else {
        //     return false;
        // }
    }

    protected isUserMemberOfOrganisation(event: MicrosoftEvent): boolean {
        let match = false;
        if (event.user_id) {
            const name = event.user_id;
            match = this.users.some(
                (u) => u.userPrincipalName?.toLowerCase() === name.toLowerCase()
            );
        }
        // TODO: do actual check
        return match;
    }

    protected isForwardedOutsideOfOrg(event: MicrosoftEvent) {
        let match = false;
        if (event.fields && event.fields.forwarding_set && event.fields.org) {

            const destinations = [
                event.fields['forward_as_attachment_to'] || undefined,
                event.fields['forward_to'] || undefined,
                event.fields['redirect_to'] || undefined,
            ] as Array<string>;

            // TODO:  this only uses the org name as stored however it will NOT account for org alias domains.
            // we should store orgs and org aliases in the tenant object at some point
            const domains = this.domains.map((d) => d.id);
            domains.push(event.fields.org as string);

            match = destinations
                .filter((dest) => !!dest)
                .some((dest) => !domains.includes(dest.replace(/.*@/, '')));
        }
        return match;
    }

    protected isMicrosoftActivity(event: MicrosoftEvent): boolean {

        const microsoft_upns = new Set([
            'NT AUTHORITY\\SYSTEM (w3wp)',
            'NT AUTHORITY\\SYSTEM (Microsoft.Exchange.AdminApi.NetCore)'
        ].map(upn => upn.toLowerCase()));

        if (event.user_id && microsoft_upns.has(event.user_id)) {
            return true;
        }

        const microsoft_isp_names = new Set([
            'Microsoft Corporation',
            'Microsoft Azure',
            'Microsoft',
        ]);

        if (event.reputation === null || !event.reputation.isp) {
            return false;
        }

        return microsoft_isp_names.has(event.reputation.isp);
    }
}
