import { Meta } from '../../interfaces/meta.interface';
import { SiteCollectionAdminAddedClx } from '../SharePoint/SharePointSharingOperation';

type SiteCollectionAdminAddedFields = {
    ObjectId: string
}

const SiteCollectionAdminAdded: Meta<SiteCollectionAdminAddedFields> = {
    info: {
        title: 'OneDrive Site Collection Admin Added',
        description: 'A person was added as a site collection administrator. Site collection administrators have full permissions for the site collection and all subsites.',
    },
    extractor: (log: any) => {
        return {
            ObjectId: log.ObjectId
        }
    },
    classifications: SiteCollectionAdminAddedClx('OneDrive Site Collection Admin Added','A person was added as a site collection administrator.')
}

const operations = {
    'SiteCollectionAdminAdded': SiteCollectionAdminAdded
}

export default operations;