import { getDesiredAssignmentsSchema } from '../generic';
import { IOSCompliancePolicySchema } from './model';

export const updateSchema = (formValue: any, schema: IOSCompliancePolicySchema): any => {
    const restrictedApps = formValue.restrictedApps?.filter((res) => res.name && res.appId);

    if (restrictedApps?.length > 0) {
        schema.contains.properties.policy.properties.restrictedApps = {
            type: 'array',
            minItems: restrictedApps.length,
            maxItems: restrictedApps.length,
            items: {
                type: 'object',

                oneOf: restrictedApps.map((res) => ({
                    properties: {
                        name: { type: 'string', const: res.name },
                        appId: { type: 'string', const: res.appId },
                    },
                })),
            },
        };
    } else {
        schema.contains.properties.policy.properties.restrictedApps = {
            type: 'array',
            minItems: 0,
            maxItems: 0,
        };
    }

    schema.contains.properties.policy.properties.displayName.const = formValue.displayName;
    schema.contains.properties.policy.properties.description.const = formValue.description;

    schema.contains.properties.policy.properties.managedEmailProfileRequired.const =
        formValue.managedEmailProfileRequired; // boolean

    schema.contains.properties.policy.properties.securityBlockJailbrokenDevices.const =
        formValue.securityBlockJailbrokenDevices; // boolean

    schema.contains.properties.policy.properties.advancedThreatProtectionRequiredSecurityLevel.const =
        formValue.advancedThreatProtectionRequiredSecurityLevel; // boolean

    schema.contains.properties.policy.properties.deviceThreatProtectionRequiredSecurityLevel.const =
        formValue.deviceThreatProtectionRequiredSecurityLevel; // string

    // device properties
    if (!!formValue.osMinimumVersion) {
        schema.contains.properties.policy.properties.osMinimumVersion.const = formValue.osMinimumVersion;
        schema.contains.properties.policy.properties.osMinimumVersion.type = 'string';
    } else {
        schema.contains.properties.policy.properties.osMinimumVersion = { type: 'null' };
    }

    if (!!formValue.osMaximumVersion) {
        schema.contains.properties.policy.properties.osMaximumVersion.const = formValue.osMaximumVersion;
        schema.contains.properties.policy.properties.osMaximumVersion.type = 'string';
    } else {
        schema.contains.properties.policy.properties.osMaximumVersion = { type: 'null' };
    }

    if (!!formValue.osMinimumBuildVersion) {
        schema.contains.properties.policy.properties.osMinimumBuildVersion.const = formValue.osMinimumBuildVersion;
        schema.contains.properties.policy.properties.osMinimumBuildVersion.type = 'string';
    } else {
        schema.contains.properties.policy.properties.osMinimumBuildVersion = { type: 'null' };
    }

    if (!!formValue.osMaximumBuildVersion) {
        schema.contains.properties.policy.properties.osMaximumBuildVersion.const = formValue.osMaximumBuildVersion;
        schema.contains.properties.policy.properties.osMaximumBuildVersion.type = 'string';
    } else {
        schema.contains.properties.policy.properties.osMaximumBuildVersion = { type: 'null' };
    }

    // system security
    schema.contains.properties.policy.properties.passcodeRequired.const = formValue.passcodeRequired; // boolean // check

    if (!!formValue.passcodeRequired) {
        schema.contains.properties.policy.properties.passcodeBlockSimple = {
            type: 'boolean',
            const: formValue.passcodeBlockSimple ?? false,
        }; // boolean
        schema.contains.properties.policy.properties.passcodeRequiredType = {
            type: 'string',
            const: formValue.passcodeRequiredType,
        }; // string
        schema.contains.properties.policy.properties.passcodeMinimumLength = {
            type: 'number',
            const: formValue.passcodeMinimumLength,
        }; // number

        schema.contains.properties.policy.properties.passcodeMinutesOfInactivityBeforeLock = {
            type: 'number',
            const: formValue.passcodeMinutesOfInactivityBeforeLock,
        }; // number
        schema.contains.properties.policy.properties.passcodeExpirationDays = {
            type: 'number',
            const: formValue.passcodeExpirationDays,
        };

        schema.contains.properties.policy.properties.passcodePreviousPasscodeBlockCount = {
            type: 'number',
            const: formValue.passcodePreviousPasscodeBlockCount,
        }; // number

        if (formValue.passcodeMinimumCharacterSetCount !== 'notConfigured') {
            schema.contains.properties.policy.properties.passcodeMinimumCharacterSetCount = {
                type: 'number',
                const: formValue.passcodeMinimumCharacterSetCount,
            }; // number
        } else {
            schema.contains.properties.policy.properties.passcodeMinimumCharacterSetCount = {
                type: 'null',
            };
        }

        if (formValue.passcodeMinutesOfInactivityBeforeScreenTimeout !== 'notConfigured') {
            schema.contains.properties.policy.properties.passcodeMinutesOfInactivityBeforeScreenTimeout = {
                type: 'number',
                const: formValue.passcodeMinutesOfInactivityBeforeScreenTimeout,
            }; // number
        } else {
            schema.contains.properties.policy.properties.passcodeMinutesOfInactivityBeforeScreenTimeout = {
                type: 'null',
            };
        }

        if (formValue.passcodeMinimumCharacterSetCount !== 'notConfigured') {
            schema.contains.properties.policy.properties.passcodeMinimumCharacterSetCount = {
                type: 'number',
                const: formValue.passcodeMinimumCharacterSetCount,
            }; // number
        } else {
            schema.contains.properties.policy.properties.passcodeMinimumCharacterSetCount = {
                type: 'null',
            };
        }
    } else {
        delete schema.contains.properties.policy.properties.passcodeBlockSimple;
        delete schema.contains.properties.policy.properties.passcodeMinimumLength;
        delete schema.contains.properties.policy.properties.passcodeMinimumCharacterSetCount;
        delete schema.contains.properties.policy.properties.passcodeMinutesOfInactivityBeforeLock;
        delete schema.contains.properties.policy.properties.passcodeMinutesOfInactivityBeforeScreenTimeout;

        delete schema.contains.properties.policy.properties.passcodeExpirationDays;
        delete schema.contains.properties.policy.properties.passcodePreviousPasscodeBlockCount;
    }

    schema.contains.properties.assignments = getDesiredAssignmentsSchema(formValue);

    return schema;
};
