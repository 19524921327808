import { Classification, Meta } from "../../interfaces/meta.interface";
import {
    fraud_ip_in_asn,
    fraud_ip_in_country, fraud_ip_in_ip,
    fraud_ip_in_region,
    fraud_ip_not_in_allowed_list,
    is_microsoft_activity, 
    not_in_allowed_ip,
user_not_in_org
} from '../statements';

type PageViewedFields = {
    ObjectId: string
}

export function PageViewedClx(title: string, description: string): Classification<PageViewedFields>[] {
    return [
        // page viewed. fraudulent IP. not in allowed list
        {
            severity: 'danger',
            title,
            description: `${description} ${fraud_ip_not_in_allowed_list}`,
            predicates: {
                isAllowedASN: false,
                isAllowedCountry: false,
                isAllowedRegion: false,
                isAllowedIP: false,
                isFraudulentIP: true,
                isMicrosoftActivity: false
            }
        },
        // page viewed. user in org. fraudulent IP, allowed IP
        {
            severity: 'warning',
            title,
            description: `${description} ${fraud_ip_in_ip}`,
            predicates: {
                isFraudulentIP: true,
                isAllowedIP: true,
                isMicrosoftActivity: false,
                isUserMemberOfOrganisation: true
            }
        },
        // page viewed. user not in org. fraudulent IP, allowed IP
        {
            severity: 'info',
            title,
            description: `${description} ${user_not_in_org} ${fraud_ip_in_ip}`,
            predicates: {
                isFraudulentIP: true,
                isAllowedIP: true,
                isMicrosoftActivity: false,
                isUserMemberOfOrganisation: false
            }
        },
        // page viewed. user in org. fraudulent IP, in allowed region.
        {
            severity: 'warning',
            title,
            description: `${description} ${fraud_ip_in_region}`,
            predicates: {
                isAllowedRegion: true,
                isFraudulentIP: true,
                isMicrosoftActivity: false,
                isUserMemberOfOrganisation: true
            }
        },
        // page viewed. user not in org. fraudulent IP, in allowed region.
        {
            severity: 'info',
            title,
            description: `${description} ${user_not_in_org} ${fraud_ip_in_region}`,
            predicates: {
                isAllowedRegion: true,
                isFraudulentIP: true,
                isMicrosoftActivity: false,
                isUserMemberOfOrganisation: false
            }
        },
        // page viewed. user in org. fraudulent IP, in allowed countries.
        {
            severity: 'warning',
            title,
            description: `${description} ${fraud_ip_in_country}`,
            predicates: {
                isAllowedCountry: true,
                isFraudulentIP: true,
                isMicrosoftActivity: false,
                isUserMemberOfOrganisation: true
            }
        },
        // page viewed. user not in org. fraudulent IP, in allowed countries.
        {
            severity: 'info',
            title,
            description: `${description} ${user_not_in_org} ${fraud_ip_in_country}`,
            predicates: {
                isAllowedCountry: true,
                isFraudulentIP: true,
                isMicrosoftActivity: false,
                isUserMemberOfOrganisation: false
            }
        },
        // page viewed. user in org. fraudulent IP, in allowed asn.
        {
            severity: 'warning',
            title,
            description: `${description} ${fraud_ip_in_asn}`,
            predicates: {
                isAllowedASN: true,
                isFraudulentIP: true,
                isMicrosoftActivity: false,
                isUserMemberOfOrganisation: true
            }
        },
        // page viewed. user not in org. fraudulent IP, in allowed asn.
        {
            severity: 'info',
            title,
            description: `${description} ${user_not_in_org} ${fraud_ip_in_asn}`,
            predicates: {
                isAllowedASN: true,
                isFraudulentIP: true,
                isMicrosoftActivity: false,
                isUserMemberOfOrganisation: false
            }
        },
        // page viewed. user in org. not in allowed list
        {
            severity: 'warning',
            title,
            description: `${description} ${not_in_allowed_ip}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isMicrosoftActivity: false,
                isUserMemberOfOrganisation: true
            }
        },
        // page viewed. user not in org. not in allowed list
        {
            severity: 'info',
            title,
            description: `${description} ${user_not_in_org} ${not_in_allowed_ip}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isMicrosoftActivity: false,
                isUserMemberOfOrganisation: false
            }
        },
        // page viewed. microsoft activity
        {
            severity: 'info',
            title,
            description: `${description} ${is_microsoft_activity}`,
            predicates: {
                isMicrosoftActivity: true
            }
        },
        // page viewed.
        {
            severity: 'info',
            title,
            description: `${description}`
        }
    ]
}

const PageViewed: Meta<PageViewedFields> = {
    info: {
        title: 'SharePoint Page Viewed',
        description: 'A page was viewed on a SharePoint site.',
    },
    extractor: (log: any) => {
        return {
            ObjectId: log.ObjectId
        }
    },
    classifications: PageViewedClx('SharePoint Page Viewed', 'A page was viewed on a SharePoint site.')
}

const operations = {
    'PageViewed': PageViewed
}

export default operations;
