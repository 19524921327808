export const AllPermissions = [
    // Graph
    'Application.ReadWrite.All',
    'AppRoleAssignment.ReadWrite.All',
    'AuditLog.Read.All',
    'DelegatedPermissionGrant.ReadWrite.All',
    'Directory.ReadWrite.All',
    'Domain.ReadWrite.All', // sway
    'Mail.ReadBasic.All',
    'MailboxSettings.ReadWrite',
    'Policy.Read.All', // sway
    'Policy.ReadWrite.ConditionalAccess', // sway
    'Reports.Read.All',
    'RoleManagement.ReadWrite.Directory',
    'User.ReadWrite.All', // Graph + SharePoint
    'UserAuthenticationMethod.ReadWrite.All',
    // Exchange Online
    'Exchange.ManageAsApp',
    // Management APIs
    'ActivityFeed.Read',
    'ActivityFeed.ReadDlp',
    'ServiceHealth.Read',
    // SharePoint
    'Sites.FullControl.All',
    'TermStore.ReadWrite.All',
    // Intue
    'DeviceManagementConfiguration.ReadWrite.All',
    'DeviceManagementServiceConfig.ReadWrite.All',
    'DeviceManagementApps.ReadWrite.All',
    'DeviceManagementManagedDevices.ReadWrite.All',
];

export function MissingRoles(current: string[]): string[] {
    return AllPermissions.filter((e) => !current.includes(e) && !current.includes(e.replace('.Read.', '.ReadWrite.')));
}

const AllPermissionsIgnoreIntune = [
    // Graph
    'Application.ReadWrite.All',
    'AppRoleAssignment.ReadWrite.All',
    'AuditLog.Read.All',
    'DelegatedPermissionGrant.ReadWrite.All',
    'Directory.ReadWrite.All',
    'Domain.ReadWrite.All', // sway
    'Mail.ReadBasic.All',
    'MailboxSettings.ReadWrite',
    'Policy.Read.All', // sway
    'Policy.ReadWrite.ConditionalAccess', // sway
    'Reports.Read.All',
    'RoleManagement.ReadWrite.Directory',
    'User.ReadWrite.All', // Graph + SharePoint
    'UserAuthenticationMethod.ReadWrite.All',
    // Exchange Online
    'Exchange.ManageAsApp',
    // Management APIs
    'ActivityFeed.Read',
    'ActivityFeed.ReadDlp',
    'ServiceHealth.Read',
    // SharePoint
    'Sites.FullControl.All',
    'TermStore.ReadWrite.All',
];

export function MissingRolesIgnoreIntune(current: string[]): string[] {
    return AllPermissionsIgnoreIntune.filter(
        (e) => !current.includes(e) && !current.includes(e.replace('.Read.', '.ReadWrite.')),
    );
}
