import { HttpErrorResponse } from '@angular/common/http';
import { map, throwError } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';

class HttpError {
    public readonly message: string;
    public readonly status: number;
    public readonly trace?: string;

    constructor(props: { message: string; status: number; trace?: string }) {
        this.message = props.message;
        this.status = props.status;
        this.trace = props.trace;
    }
}

export function HandleAjaxError(err: HttpErrorResponse) {
    let message = 'HTTP Error';

    if (typeof err.error?.message === 'string') {
        message = err.error?.message;
    } else if (typeof err.error?.innerError?.message === 'string') {
        message = err.error?.innerError?.message;
    } else if (typeof err.error?.error?.message === 'string') {
        message = err.error?.error?.message;
    } else if (typeof err.error?.message === 'string') {
        message = err.error?.message;
    } else if (typeof err.error === 'string') {
        message = err.error;
    } else if (typeof err.message === 'string') {
        message = err.message;
    }

    let trace: string = undefined;

    if (err?.error?.trace) {
        trace = err.error.trace;
    }

    const error = new HttpError({
        ...err,
        message,
        trace,
        status: err.status,
    });

    return throwError(() => error);
}
