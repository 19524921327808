import { createAction, props } from '@ngrx/store';
import { PwnedUser } from './model';

export const loadPwnedUsers = createAction('[PwnedUser] Load Users', props<{ _tenant: string }>());

export const loadPwnedUsersSuccess = createAction(
    '[PwnedUser] Load Users Success',
    props<{ _tenant: string; data: PwnedUser[] }>(),
);

export const loadPwnedUsersFailure = createAction(
    '[PwnedUser] Load Users Failure',
    props<{ _tenant: string; error: any }>(),
);