import { Classification, Meta } from "../../interfaces/meta.interface";
import {
    fraud_ip_in_asn,
    fraud_ip_in_country,
    fraud_ip_in_ip,
    fraud_ip_in_region,
    fraud_ip_not_in_allowed_list,
    is_microsoft_activity,
    not_in_allowed_ip
} from "../statements";

type SiteCollectionAdminAddedFields = {
    ObjectId: string;
};

export function SiteCollectionAdminAddedClx(
    title: string,
    description: string
): Classification<SiteCollectionAdminAddedFields>[] {
    return [
        // site admin added: fraudulent IP. not in allowed list.
        {
            severity: "danger",
            title,
            description: `${description} ${fraud_ip_not_in_allowed_list}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isFraudulentIP: true,
                isMicrosoftActivity: false,
            },
        },
        // site admin added: fraudulent IP. allowed IP.
        {
            severity: "warning",
            title,
            description: `${description} ${fraud_ip_in_ip}`,
            predicates: {
                isFraudulentIP: true,
                isAllowedIP: true,
                isMicrosoftActivity: false,
            },
        },
        // site admin added: fraudulent IP. allowed region.
        {
            severity: "warning",
            title,
            description: `${description} ${fraud_ip_in_region}`,
            predicates: {
                isFraudulentIP: true,
                isAllowedRegion: true,
                isMicrosoftActivity: false,
            },
        },
        // site admin added: fraudulent IP. in allowed countries
        {
            severity: "warning",
            title,
            description: `${description} ${fraud_ip_in_country}`,
            predicates: {
                isFraudulentIP: true,
                isAllowedCountry: true,
                isMicrosoftActivity: false,
            },
        },
        // site admin added: fraudulent IP. in allowed asn
        {
            severity: "warning",
            title,
            description: `${description} ${fraud_ip_in_asn}`,
            predicates: {
                isFraudulentIP: true,
                isAllowedASN: true,
                isMicrosoftActivity: false,
            },
        },
        // site admin added: not in allowed list.
        {
            severity: "warning",
            title,
            description: `${description} ${not_in_allowed_ip}`,
            predicates: {
                isAllowedASN: false,
                isAllowedIP: false,
                isAllowedRegion: false,
                isAllowedCountry: false,
                isMicrosoftActivity: false,
            },
        },
        // site admin added: microsoft activity
        {
            severity: "info",
            title,
            description: `${description} ${is_microsoft_activity}`,
            predicates: {
                isMicrosoftActivity: true,
            },
        },
        // site admin added
        {
            severity: "info",
            title,
            description: `${description}`,
        },
    ];
}

const SiteCollectionAdminAdded: Meta<SiteCollectionAdminAddedFields> = {
    info: {
        title: "SharePoint Site Collection Admin Added",
        description:
            "A person was added as a site collection administrator. Site collection administrators have full permissions for the site collection and all subsites.",
    },
    extractor: (log: any) => {
        return {
            ObjectId: log.ObjectId,
        };
    },
    classifications: SiteCollectionAdminAddedClx(
        "SharePoint Site Collection Admin Added",
        "A person was added as a site collection administrator."
    ),
};

const operations = {
    SiteCollectionAdminAdded: SiteCollectionAdminAdded,
};

export default operations;
