export interface CapItem {
    displayName: string;
    id: string;
    inBaselineSchema?: boolean;
    inPolicy?: boolean;
}

export enum RowType {
    includeUsers = 'includeUsers',
    excludeUsers = 'excludeUsers',
    includeRoles = 'includeRoles',
    excludeRoles = 'excludeRoles',
    includeGroups = 'includeGroups',
    excludeGroups = 'excludeGroups',
}
